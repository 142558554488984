<template lang="html">
  <div>
    <!-- Modal Afilia tu empresa -->
    <b-modal ref="modal-afiliar-empresa" modal-class="modal-s1" @hidden="destroyModal" centered hide-footer no-close-on-backdrop no-close-on-esc title="">
      <div class="box-top">
        <h5 class="modal-title">Afília tu Empresa</h5>
      </div>

      <b-form class="col-12 px-2" @submit="onSubmit">
        <div class="row">
          <b-form-group class="col-12 custom-f-group-1"
            label="Empresa:">
            <b-form-input size="sm"
              v-model="membershipForm.empresa"
              type="text"
              placeholder="Nombre de la Empresa"
              required
            ></b-form-input>
          </b-form-group>

          <b-form-group class="col-12 custom-f-group-1"
            label="Giro:">
            <b-form-input size="sm"
              v-model="membershipForm.giro"
              type="text"
              placeholder="A que se dedica la empresa"
              required
            ></b-form-input>
          </b-form-group>

          <b-form-group class="col-12 custom-f-group-1"
            label="Email:">
            <b-form-input size="sm"
              v-model="membershipForm.email"
              type="email"
              placeholder="correo@email.com"
              required
            ></b-form-input>
          </b-form-group>

          <b-form-group class="col-12 custom-f-group-1"
            label="Teléfono (10 dígitos):">
            <b-form-input size="sm"
              v-model="membershipForm.telefono"
              type="text"
              placeholder="1234567890"
              minlength="10"
              maxlength="10"
              required
            ></b-form-input>
          </b-form-group>

          <div class="col-12 mt-2">
            <b-button type="submit" class="w-100 btn-s-1 btn-lg blue">Afiliar Empresa</b-button>
          </div>
        </div>
      </b-form>
    </b-modal>
    <!--  -->
  </div>
</template>

<script>
export default {
  data(){
    return{
      membershipForm: {
        empresa: null,
        giro: null,
        email: null,
        telefono: null,
      },
    }
  },

  methods: {
    destroyModal(){
      setTimeout(()=>{ this.$root.show_ModalAfiliarMarcaEmpresa = false; }, 350);
    },

    onSubmit(event) {
      event.preventDefault();

      axios.get(tools.url('https://admin.easyfit.directory/sanctum/csrf-cookie')).then(response => {
          this.$axios.post('/afiliaempresas', this.membershipForm).then(response => {
            console.log(response.data);
              let row = response.data;
              alert('Registro completado!');
          });
      });

      setTimeout(()=>{ this.$root.show_ModalAfiliarMarcaEmpresa = false; }, 350);
    },
  },

  watch: {
   '$root.show_ModalAfiliarMarcaEmpresa': function(value, oldValue){ // Mostrar u ocultar este modal/componente
      if(value){
        this.$refs['modal-afiliar-empresa'].show();
      }else{
        this.$refs['modal-afiliar-empresa'].hide();
      }
    }
  },
}
</script>
