<template lang="html">
  <div id="contact-page">

    <section class="container oversized-container main-section">
      <div class="row">
        <div class="col-12 col-title">
          <h5 class="title-s-1 txt-blue">Atención a socios</h5>
        </div>

        <div class="col-md-6 col-lg-4 col-form">
          <b-form @submit="onSubmit">
            <b-form-group class="custom-f-group-2"
              label="Nombre:">
              <b-form-input
                v-model="form.nombre"
                type="text"
                size="sm"
                placeholder="Nombre y apellidos"
                required
              ></b-form-input>
            </b-form-group>

            <b-form-group class="custom-f-group-2"
              label="Sucursal:">
              <b-form-select v-model="form.sucursales_id" size="sm" v-bind:class="{ 'not-filled' : form.sucursal == null }">
                <b-form-select-option :value="null">Selecciona una Sucursal</b-form-select-option>
                <b-form-select-option :value="s.id" v-for="(s, scInx) in $root.sucursales" :key="'scInx-'+scInx">{{ s.ciudad }} - {{ s.nombre }}</b-form-select-option>
              </b-form-select>
            </b-form-group>

            <b-form-group class="custom-f-group-2"
              label="Email:">
              <b-form-input
                v-model="form.email"
                type="email"
                size="sm"
                placeholder="correo@email.com"
                required
              ></b-form-input>
            </b-form-group>

            <b-form-group class="custom-f-group-2"
              label="Teléfono:">
              <b-form-input
                v-model="form.telefono"
                type="text"
                size="sm"
                placeholder="0000000000"
                minlength="10"
                maxlength="10"
                required
              ></b-form-input>
            </b-form-group>

            <b-form-group class="custom-f-group-2"
              label="Mensaje:">
              <b-form-textarea
                v-model="form.mensaje"
                placeholder="Escribe tu mensaje"
                rows="4"
                max-rows="4"
              ></b-form-textarea>
            </b-form-group>

            <p class="text-center">
              <b-button type="submit" class=" btn-s-1 green rounded-0 btn-send">ENVIAR</b-button>
            </p>
          </b-form>
        </div>

        <div class="col-md-6 col-lg-8 mt-4 mt-md-0 col-map">
          <GmapMap
            :center="mapCenter"
            :zoom="11.70">
            <GmapMarker
              :key="'marker'+index"
              v-for="(m, index) in markers"
              :position="m.position"
              :clickable="true"
              :draggable="false"
              @click="mapCenter = m.position"
            />
          </GmapMap>

          <!-- <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1866.567450675013!2d-103.431978!3d20.66409!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x938941f6d444b1b7!2sEASY%20FIT%20LA%20ESTANCIA!5e0!3m2!1ses!2smx!4v1648847776129!5m2!1ses!2smx" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> -->
        </div>

        <sweet-modal ref="modalalert" :icon="modal.icon">
            <p style="font-weight: 500;">{{modal.msg}}</p>
            <br>
            <button @click="$refs.modalalert.close()" class="btn btn-info" style="background-color: #e37222;border-color: #e37222;width: 150px;">Aceptar</button>
        </sweet-modal>

      </div>
    </section>


  </div>
</template>

<script>
export default {
  data(){
    return{
      form: {
        nombre: null,
        email:null,
        telefono:null,
        mensaje:null,
        sucursales_id: null,
      },

      // == Google maps  ==
      mapCenter: {lat: 20.6850701, lng: -103.4026191 },
      markers: [
        { position: { lat: 20.7025502, lng: -103.3607201 } }, // Avila
        { position: {lat: 20.7500385, lng: -103.4244537 } }, // Plaza Aleira
        { position: {lat: 21.1491892, lng: -101.6507641 } }, // Leon
        { position: {lat: 20.7523822, lng: -103.3854515 } }, // Cañadas
        { position: {lat: 20.6640019, lng: -103.4340648 } }, // La Estancia
        { position: {lat: 20.6116662, lng: -103.4176352 } }, // ITESO
        { position: {lat: 20.5888755, lng: -103.4472188 } }, // Plaza las Villas
        { position: {lat: 20.6869675, lng: -103.297241 } }, // Terraza Oblatos
        { position: {lat: 20.6320865, lng: -103.322563 } }, // Tlaquepaque
      ],
       modal:{
        icon:null,
        msg:null
      }
      // == ==
    }
  },

  methods: {
    onSubmit(event) {
      event.preventDefault();
        
        axios.post(tools.url("/api/atencionsocio"),this.form).then((response)=>{
              this.form.nombre = null;
              this.form.email = null;
              this.form.telefono = null;
              this.form.mensaje = null;
              this.form.sucursales_id = null;

              

              this.modal.icon = 'success';
              this.modal.msg = 'Gracias por tu mensaje , nos comunicaremos contigo en los siguiente dias.';
              this.$refs.modalalert.open();

          }).catch((error)=>{
              alert('');

              this.modal.icon = 'error';
              this.modal.msg = 'Ocurrio un error al guardar la información';
              this.$refs.modalalert.open();
        });

    },
  },
}
</script>
