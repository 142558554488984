<template lang="html">
  <div id="article-page">

    <div class="container content-section">
      <div class="row">
        <div class="col-12 col-title">
          <h5 class="title-s-1 txt-blue">Bolsa de trabajo</h5>
        </div>
      </div>

      <div class="row">
        <!-- Artículo actual -->
        <div class="col-12 col-article">
          <div class="row box-joss">
            <div class="col-lg-4 mb-3">
              <img class="image-s2" :src="job.image">
            </div>

            <div class="col-lg-8">
              <h1 class="title">{{ job.titulo }}</h1>

              <div class="descr" v-html="job.cuerpo"></div>
            </div>
          </div>
        </div>
        <!--  -->
      </div>
    </div>

  </div>
</template>

<script>
export default {
  data(){
    return{
      job: [],
    }
  },

  methods: {
    getJob: function(){
      axios.get(tools.url("/api/bolsatrabajos/"+this.$route.params.id)).then((response)=>{
        this.job = response.data
      }).catch((error)=>{
        console.error('Error al obtener la info de Bolsa de trabajo.');
      });
    }
  },

  beforeMount(){
    this.getJob();
  },

  mounted(){
  },

  watch: {
   '$route' (to, from){
      this.getJob();
    }
  }
}
</script>
