<template lang="html">
  <div id="contact-page">

    <section class="container oversized-container main-section">
      <div class="row">
        <div class="col-12 col-title">
          <h5 class="title-s-1 txt-blue">Contacto</h5>
        </div>

        <div class="col-md-6 col-lg-4 col-form">
          <b-form @submit="onSubmit">
            <b-form-group class="custom-f-group-2"
              label="Nombre:">
              <b-form-input
                v-model="form.nombre"
                type="text"
                size="sm"
                placeholder="Nombre y apellidos"
                required
              ></b-form-input>
            </b-form-group>

            <b-form-group class="custom-f-group-2"
              label="Sucursal:">
              <b-form-select v-model="form.sucursal" size="sm" v-bind:class="{ 'not-filled' : form.sucursal == null }">
                <b-form-select-option :value="null">Selecciona una Sucursal</b-form-select-option>
                <b-form-select-option :value="s.ciudad +' - '+ s.nombre" v-for="(s, scInx) in $root.sucursales" :key="'scInx-'+scInx">{{ s.ciudad }} - {{ s.nombre }}</b-form-select-option>
              </b-form-select>
            </b-form-group>

            <b-form-group class="custom-f-group-2"
              label="Email:">
              <b-form-input
                v-model="form.email"
                type="email"
                size="sm"
                placeholder="correo@email.com"
                required
              ></b-form-input>
            </b-form-group>

            <b-form-group class="custom-f-group-2"
              label="Teléfono:">
              <b-form-input
                v-model="form.tel"
                type="text"
                size="sm"
                placeholder="0000000000"
                minlength="10"
                maxlength="10"
              ></b-form-input>
            </b-form-group>

            <b-form-group class="custom-f-group-2"
              label="Mensaje:">
              <b-form-textarea
                v-model="form.mensaje"
                placeholder="Escribe tu mensaje"
                rows="4"
                max-rows="4"
                required
              ></b-form-textarea>
            </b-form-group>

            <p class="text-center">
              <b-button type="submit" class=" btn-s-1 green rounded-0 btn-send">ENVIAR</b-button>
            </p>
          </b-form>
        </div>

        <div class="col-md-6 col-lg-8 mt-4 mt-md-0 col-map">
          <GmapMap
            :center="mapCenter"
            :zoom="11.70">
            <GmapMarker
              :key="'marker'+mapInx"
              v-for="(m, mapInx) in offices"
              :position="m.position"
              :clickable="true"
              :draggable="false"
              @click="markAction(mapInx)"
            />
          </GmapMap>

          <!-- <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1866.567450675013!2d-103.431978!3d20.66409!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x938941f6d444b1b7!2sEASY%20FIT%20LA%20ESTANCIA!5e0!3m2!1ses!2smx!4v1648847776129!5m2!1ses!2smx" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> -->
        </div>
      </div>
    </section>

    <!-- <section class="container oversized-container branch-offices-section">
      <div class="row row-content" v-for="(item, iInx) in offices" :key="'iInx-'+iInx">
        <div class="col-12 col-title">
          <h5 class="title-s-1 txt-blue">Sucursales {{ item.state }}</h5>
        </div>

        <div class="col-lg-6 col-branch-office" v-for="(subitem, siInx) in item.locations" :key="iInx+'-siInx-'+siInx">
          <div class="col col-image">
            <div class="placed-backg box-image" v-bind:style="{ backgroundImage: 'url('+subitem.imgURL+')' }"></div>
          </div>

          <div class="col col-info">
            <h5 class="title">{{ subitem.name }}</h5>

            <h6 class="subtitle">Dirección:</h6>
            <p v-html="subitem.address"></p>

            <h6 class="mt-2 mt-sm-3 subtitle" v-if="subitem.phone">Teléfono:</h6>
            <p v-if="subitem.phone">{{ subitem.phone }}</p>

            <h6 class="mt-2 mt-sm-3 subtitle">Horarios:</h6>
            <p v-html="subitem.schedule"></p>
          </div>
        </div>
      </div>
    </section> -->

    <b-modal modal-class="modal-s2" ref="modal-sucursal" size="lg" :title="modalInfo.name" hide-footer centered>
      <!-- <h5 class="title">Título del modal</h5> -->

      <div class="box-description">
        <h6 class="f-w-700">DIRECCIÓN</h6>
        <div class="d-block" v-html="modalInfo.address"></div>

        <h6 class="mt-3 f-w-700" v-if="modalInfo.phone">TELÉFONOS</h6>
        <div class="d-block" v-if="modalInfo.phone" v-html="modalInfo.phone"></div>

        <h6 class="mt-3 f-w-700">HORARIOS</h6>
        <div class="d-block" v-html="modalInfo.schedule"></div>

        <p class="mt-4">
          <a style="cursor: pointer;" class="txt-green f-w-600" target="_blank" :href="modalInfo.googleMap"><i class="fas fa-map-marker-alt"></i> Ver mapa</a>
        </p>
      </div>
    </b-modal>

  </div>
</template>

<script>
export default {
  data(){
    return{
      form: {
        nombre: null,
        sucursal: null,
        email: null,
        tel: null,
        mensaje: null,
      },

      modalInfo: {
        name: null,
        address: null,
        phone: null,
        schedule: null,
        googleMap: null,
      },

      // == Google maps  ==
      mapCenter: {lat: 20.6850701, lng: -103.4026191 },
      // == ==

      offices: [
        {
          name: 'Plaza Galerías las Torres',
          address: 'Blvd. Juan Alonso de Torres Pte. 1315, Jardines de Los Naranjos, Santa Rosa de Lima Sur, 37200 León, Gto.',
          phone: null,
          schedule: 'Lunes a jueves 5:30–23:00<br />Viernes 5:30–22:00<br />Sábados 7:30–15:00<br />Domingo 9:00–14:00',
          imgURL: 'public/images/pages/branch-offices/galerias-leon.jpg',
          position: {lat: 21.1491892, lng: -101.6507641 },
          googleMap: 'https://goo.gl/maps/Kr1emTRAr1sf7Ca39',
        },
        {
          name: 'Plaza Aleira',
          address: 'Av Juan Gil Preciado #2001<br />El Tigre<br />CP. 45134 Zapopan, Jal.',
          phone: null,
          schedule: 'Lunes a Jueves 5:30 – 23:00<br />Viernes 5:30 – 22:00<br />Sábados 7:30 – 15:00<br />Domingos 9:00 – 14:00',
          imgURL: 'public/images/pages/branch-offices/aleira.jpg',
          position: {lat: 20.7500385, lng: -103.4244537 },
          googleMap: 'https://goo.gl/maps/jhw61txBuHzT4pbPA',
        },
        {
          name: 'Ávila Camacho',
          address: 'Avenida Manuel Ávila Camacho #1650<br />Niños Heroes<br />CP. 44260 Guadalajara, Jal.',
          phone: null,
          schedule: 'Lunes a Jueves 5:30 – 23:00<br />Viernes 5:30 – 22:00<br />Sábados 7:30 – 15:00',
          imgURL: 'public/images/pages/branch-offices/avila-camacho.jpg',
          position: { lat: 20.7025502, lng: -103.3607201 },
          googleMap: 'https://goo.gl/maps/iWUHzTXcPGmAgZUf8'
        },
        {
          name: 'Cañadas',
          address: 'Av. Río Blanco 105<br />Bosques del Centinela<br />CP. 45130 Zapopan, Jal.',
          phone: null,
          schedule: 'Lunes a Jueves 5:30 – 23:00<br />Viernes 5:30 – 22:00<br />Sábados 7:30 – 15:00<br />Domingos 9:00 – 14:00',
          imgURL: 'public/images/pages/branch-offices/canadas.jpg',
          position: {lat: 20.7523822, lng: -103.3854515 },
          googleMap: 'https://goo.gl/maps/oX6McPhNjKiXquzf8'
        },
        {
          name: 'La Estancia',
          address: 'Avenida Rafael Sanzio #634<br />Arcos de Guadalupe<br />CP. 45037 Zapopan, Jal.',
          phone: null,
          schedule: 'Lunes a Jueves 5:30 – 23:00<br />Viernes 5:30 – 22:00<br />Sábados 7:30 – 15:00<br />Domingos 9:00 – 14:00 ',
          imgURL: 'public/images/pages/branch-offices/la-estancia.jpg',
          position: {lat: 20.6640019, lng: -103.4340648 },
          googleMap: 'https://goo.gl/maps/mYRHNMZGSXw97zNS7'
        },
        {
          name: 'ITESO',
          address: 'Avenida Camino al ITESO y Anillo Periférico Sur Manuel Gómez Morín #8636,<br />Colonia Santa María Tequepezpan,<br />CP. 45530 San Pedro Tlaquepaque, Jal.',
          phone: null,
          schedule: 'Lunes a Jueves 5:30 – 23:00<br />Viernes 5:30 – 22:00<br />Sábados 7:30 – 15:00<br />Domingos 9:00 – 14:00',
          imgURL: 'public/images/pages/branch-offices/ITESO.jpg',
          position: {lat: 20.6116662, lng: -103.4176352 },
          googleMap: 'https://goo.gl/maps/KWbRggpBWG9CMies6'
        },
        {
          name: 'Plaza las Villas',
          address: 'Avenida López Mateos Sur #1450<br />Local 22, Plaza Las Villas<br />CP: 45647 Tlajomulco de Zúñiga, Jal.',
          phone: null,
          schedule: 'Lunes a Jueves 5:30 – 23:00<br />Viernes 5:30 – 22:00<br />Sábados 7:30 – 15:00<br />Domingos 9:00 – 14:00',
          imgURL: 'public/images/pages/branch-offices/las-villas.jpg',
          position: {lat: 20.5888755, lng: -103.4472188 },
          googleMap: 'https://goo.gl/maps/2zpGNNifasLuyg5v9'
        },
        {
          name: 'Terraza Oblatos',
          address: 'Avenida Circunvalación Oblatos #2700, Local AN-04,<br />Plaza Gran Terraza Oblatos,<br />C.P. 44720 Guadalajara, Jal.',
          phone: null,
          schedule: 'Lunes a Jueves 5:30 – 23:00<br />Viernes 5:30 – 22:00<br />Sábados 7:30 – 15:00<br />Domingos 9:00 – 14:00',
          imgURL: 'public/images/pages/branch-offices/terraza-oblatos.jpg',
          position: {lat: 20.6869675, lng: -103.297241 },
          googleMap: 'https://goo.gl/maps/6JAnrXoy6gZrUGiKA'
        },
        {
          name: 'Tlaquepaque',
          address: 'Av. Niños Héroes #419<br />Alamo Lomas<br />Cp. 45560 Guadalajara, Jal.',
          phone: null,
          schedule: 'Lunes a Jueves 5:30 – 23:00<br />Viernes 5:30 – 22:00<br />Sábados 7:30 – 15:00<br />Domingos 9:00 – 14:00',
          imgURL: 'public/images/pages/branch-offices/tlaquepaque.jpg',
          position: {lat: 20.6320865, lng: -103.322563 },
          googleMap: 'https://goo.gl/maps/YHdMsySGuTFFX4Jv8'
        },
      ],
    }
  },

  methods: {
    markAction(idx){
      this.modalInfo = this.offices[idx];
      this.mapCenter = this.offices[idx].position;

      this.$refs['modal-sucursal'].show();
    },

    makeToast(variant = null, msg, title) {
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        solid: true,
        toaster: 'b-toaster-top-left',
        appendToast: true
      })
    },

    onSubmit(event) {
      event.preventDefault();

      var params = this.form;
      var apiURL = tools.url("/api/contact");

      axios.post( apiURL,params )
      .then( (response) => {
        // alert(response.data.response);
        this.makeToast('success', response.data.response, 'Mensaje enviado');
        // alert(response.data.response);

        for (let prop in this.form) {
          this.form[prop] = '';
        }
      })
      .catch( (error) => {
        // console.log(error.response.data);
        let errors = error.response.data;

        for (let prop in errors) {
          // console.log(errors[prop]);
          this.makeToast('danger', errors[prop], '¡Error!');
        }
      });
    },
  },

  mounted(){
  }
}
</script>
