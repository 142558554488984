<template lang="html">
  <div>
    <!-- Modal Tramite y descarga en Linea -->
    <b-modal ref="modal-visita-guiada" modal-class="modal-s1" size="lg" @hidden="destroyModal" centered hide-footer no-close-on-backdrop no-close-on-esc title="">
      <div class="box-top">
        <h5 class="modal-title">Agenda tu visita</h5>
      </div>

      <b-form class="col-12 px-2" @submit="onSubmitVisit">
        <div class="row">
          <b-form-group class="col-md-6 custom-f-group-1"
            label="Nombre:">
            <b-form-input size="sm"
              v-model="visitaForm.nombre"
              type="text"
              placeholder="Nombre(s)"
              required
            ></b-form-input>
          </b-form-group>

          <b-form-group class="col-md-6 custom-f-group-1"
            label="Apellidos:">
            <b-form-input size="sm"
              v-model="visitaForm.apellidos"
              type="text"
              placeholder="Apellidos"
              required
            ></b-form-input>
          </b-form-group>

          <b-form-group class="col-md-6 custom-f-group-1"
            label="Fecha de nacimiento:">
            <b-form-input size="sm"
              v-model="visitaForm.fecha_nacimiento"
              type="date"
              placeholder="DD - MM - AAAA"
              required
            ></b-form-input>
          </b-form-group>

          <b-form-group class="col-md-6 custom-f-group-1"
            label="Teléfono (10 dígitos):">
            <b-form-input size="sm"
              v-model="visitaForm.telefono"
              type="text"
              placeholder="1234567890"
              minlength="10"
              maxlength="10"
              required
            ></b-form-input>
          </b-form-group>

          <b-form-group class="col-12 custom-f-group-1"
            label="Correo electrónico:">
            <b-form-input size="sm"
              v-model="visitaForm.correo"
              type="email"
              placeholder="correo@email.com"
              required
            ></b-form-input>
          </b-form-group>

          <div class="col-12 mt-2">
            <b-button type="submit" class="w-100 btn-s-1 btn-lg blue">Agendar</b-button>
          </div>
        </div>
      </b-form>
    </b-modal>
    <!--  -->
  </div>
</template>

<script>
export default {
  data(){
    return{
      visitaForm: {
        nombre: null,
        apellidos: null,
        fecha_nacimiento: null,
        telefono: null,
        correo: null,
      },
    }
  },

  methods: {
    destroyModal(){
      setTimeout(()=>{ this.$parent.showModalVisitaGuiada = false; }, 350);
    },

    onSubmitVisit(event) {
      event.preventDefault();
       axios.post(tools.url("/api/visitas"),this.visitaForm).then((response)=>{
              this.visitaForm.nombre = null;
              this.visitaForm.apellidos = null;
              this.visitaForm.fecha_nacimiento = null;
              this.visitaForm.telefono = null;
              this.visitaForm.correo = null;
              
              this.$parent.showModalVisitaGuiada = false;
              
              this.$parent.modal.icon = 'success';
              this.$parent.modal.msg = 'Gracias por tu interés, nos comunicaremos contigo para confirmar la fecha en que deseas hacer tu visita.';
              this.$parent.$refs.modalalert.open();
              
          }).catch((error)=>{
               this.$parent.modal.icon = 'success';
              this.$parent.modal.msg = 'Ocurrio un error al guardar la información';
              this.$parent.$refs.modalalert.open();
      });
     /* axios.get(tools.url('https://admin.easyfit.directory/sanctum/csrf-cookie')).then(response => {
          this.$axios.post('/visitas', this.visitaForm).then(response => {
            console.log(response.data);
              let row = response.data;
              alert('Registro completado!');
          });
      });*/

      //setTimeout(()=>{ this.$parent.showModalVisitaGuiada = false; }, 350);
    },
  },

  mounted(){
    this.$refs['modal-visita-guiada'].show();
  }
}
</script>
