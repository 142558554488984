<template lang="html">
  <div>
    <!-- Modal Tramite y descarga en Linea -->
    <b-modal ref="modal-clase-prueba" modal-class="modal-s1" size="lg" @hidden="destroyModal" centered hide-footer no-close-on-backdrop no-close-on-esc title="">
      <div class="box-top">
        <h5 class="modal-title">Agenda tu clase de prueba gratis</h5>
      </div>

      <b-form class="col-12 px-2" @submit="onSubmitClase">
        <div class="row">
          <b-form-group class="col-md-6 custom-f-group-1"
            label="Nombre:">
            <b-form-input size="sm"
              v-model="claseForm.nombre"
              type="text"
              placeholder="Nombre(s)"
              required
            ></b-form-input>
          </b-form-group>

          <b-form-group class="col-md-6 custom-f-group-1"
            label="Apellidos:">
            <b-form-input size="sm"
              v-model="claseForm.apellidos"
              type="text"
              placeholder="Apellidos"
              required
            ></b-form-input>
          </b-form-group>

          <b-form-group class="col-md-6 custom-f-group-1"
            label="Número de membresía:">
            <b-form-input size="sm"
              v-model="claseForm.numeroMembresia"
              type="text"
              placeholder="Introduce tu número de membresía"
              required
            ></b-form-input>
          </b-form-group>

          <b-form-group class="col-md-6 custom-f-group-1"
            label="Teléfono (10 dígitos):">
            <b-form-input size="sm"
              v-model="claseForm.tel"
              type="text"
              placeholder="1234567890"
              minlength="10"
              maxlength="10"
              required
            ></b-form-input>
          </b-form-group>

          <b-form-group class="col-12 custom-f-group-1"
            label="Correo electrónico:">
            <b-form-input size="sm"
              v-model="claseForm.email"
              type="email"
              placeholder="correo@email.com"
              required
            ></b-form-input>
          </b-form-group>

          <div class="col-12 mt-2">
            <b-button type="submit" class="w-100 btn-s-1 btn-lg blue">Agendar</b-button>
          </div>
        </div>
      </b-form>
    </b-modal>
    <!--  -->
  </div>
</template>

<script>
export default {
  data(){
    return{
      claseForm: {
        nombre: null,
        apellidos: null,
        numeroMembresia: null,
        tel: null,
        email: null,
      },
    }
  },

  methods: {
    destroyModal(){
      setTimeout(()=>{ this.$parent.showModalClasePrueba = false; }, 350);
    },

    onSubmitClase(event) {
      this.$axios.get(tools.url('https://admin.easyfit.directory/sanctum/csrf-cookie')).then(response => {
          this.$axios.post('/visitas', this.claseForm).then(response => {
            console.log(response.data);
              let row = response.data;
              alert('Registro completado!');
          });
      });

      setTimeout(()=>{ this.$parent.showModalClasePrueba = false; }, 350);
    },
  },

  mounted(){
    this.$refs['modal-clase-prueba'].show();
  }
}
</script>
