<template lang="html">
  <footer id="footer">
    <section class="newsletter-section">
      <h2>Newsletter</h2>

      <b-form @submit.prevent="onSubmit">
        <b-form-group>
          <b-form-input
            v-model="form.email"
            type="email"
            required
            placeholder="Correo electrónico"
          ></b-form-input>
        </b-form-group>

        <b-button type="submit" class="btn-send">Enviar</b-button>
      </b-form>
    </section>

    <section class="footer-section">
      <div class="container oversized-container">
        <div class="row">
          <div class="col-12 col-sm-6 col-lg col-footer">
            <h6>Ayuda</h6>

            <p>
              <router-link to="/comunicados">Comunicados</router-link>
            </p>
            <p>
              <router-link to="/aviso-de-privacidad">Aviso de Privacidad</router-link>
            </p>
            <p>
              <router-link to="/terminos-y-condiciones">Términos y Condiciones</router-link>
            </p>
            <p>
              <router-link to="/preguntas-frecuentes">Preguntas frecuentes</router-link>
            </p>
          </div>

          <div class="col-12 col-sm-6 col-lg col-footer">
            <h6>Mapa del sitio</h6>

            <p>
              <router-link to="/planes">Planes</router-link>
            </p>
            <p>
              <router-link to="/instalaciones">Instalaciones</router-link>
            </p>
            <p>
              <router-link to="/clases">Clases</router-link>
            </p>
            <p>
              <router-link to="/beneficios">Beneficios</router-link>
            </p>
          </div>

          <div class="col-lg col-logo">
            <router-link to="/">
              <img src="public/images/logo-white.svg">
            </router-link>
          </div>

          <div class="col-12 col-sm-6 col-lg col-footer col-r">
            <h6>Correos Electrónicos</h6>

            <p>
              {{ info.email }}
            </p>
          </div>

          <div class="col-12 col-sm-6 col-lg col-footer col-r">
            <h6>Teléfonos</h6>

            <p>
              <i class="fab fa-whatsapp mr-1"></i> {{ info.telefono }}<br />
            </p>
          </div>

          <div class="col-12 col-networks">
            <div class="d-block">
              <a class="trans-250 link" target="_blank" :href="info.facebook"><i class="fab fa-facebook-square"></i></a>
              <a class="trans-250 link" target="_blank" :href="info.instagram"><i class="fab fa-instagram"></i></a>
              <!-- <a class="trans-250 link" href="#"><i class="fab fa-pinterest"></i></a> -->
            </div>

            <!-- <span class="at">@EASYFITGDL</span> -->
            <!-- <span class="hashtag">#<u class="pi">Decora</u><u class="bl">tu</u><u class="pu">Mundo</u></span> -->
          </div>

          <div class="col-12 col-copyright">
            <p>easy FIT {{ currentYear }}</p>
          </div>
        </div>
      </div>
    </section>

  </footer>
</template>

<script>
import axios from 'axios';
export default {
  data(){
    return{
      currentYear: 0,

      info:[],

      form: {
        email: null
      },
    }
  },

  methods:{
    makeToast(variant = null, msg, title) {
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        solid: true,
        toaster: 'b-toaster-top-right',
        appendToast: true
      });
    },

    async onSubmit() {
      var r = confirm("¿Deseas regístrate en newsletter? ");
      if (r == true) {
        await axios.post('http://127.0.0.1:8000/api/contact', this.form).then((response)=>{
          this.makeToast('default', response.data.response, 'Correo registrado');
          this.form = {
            name: null,
            email: null,
            msg: null,
            check: null
          };
        }).catch( (error)=> {
          // console.log(error.response);
          let errors = error.response.data;
          // console.log(errors);
          this.makeToast('danger', error.response.data.name, 'Error');
          this.makeToast('danger', error.response.data.email, 'Error');
          this.makeToast('danger', error.response.data.phone, 'Error');
          this.makeToast('danger', error.response.data.msg, 'Error');
        });
      }
    },
  },

  beforeMount(){
    this.currentYear = new Date().getFullYear();

    axios.get(tools.url("/api/info")).then((response)=>{
      this.info = response.data
    }).catch((error)=>{
      console.error('Error al obtener la info del footer.');
    });
  },
}
</script>
