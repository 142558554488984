<template>
  <div id="home-page">

    <section class="banner-section">
      <swiper class="swiper s-desktop" :options="bannersOptions">
        <swiper-slide v-for="(b, hbdhInx) in banners.pc" :key="'hbdhInx-'+hbdhInx">
          <a :href="b.url ? b.url : '#'">
            <img :src="b.imgURL">
          </a>
        </swiper-slide>


        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
      </swiper>

      <swiper class="swiper s-mobile" :options="bannersOptions">
        <swiper-slide v-for="(b, hbmhInx) in banners.movil" :key="'hbmhInx-'+hbmhInx">
          <a :href="b.url ? b.url : '#'">
            <img :src="b.imgURL">
          </a>
        </swiper-slide>


        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
      </swiper>
    </section>

    <section class="benefits-section">
      <div class="container">
        <div class="row justify-content-center ">
          <div class="col-md-8 col-lg-6 col-bg-button">
            <a class="flex-center-xy t-250 trial" @click="showModalMembresiaDia = true">
              <div>
                <p class="title">Regístrate para 1 día de prueba</p>
                <p>Sin costo y con todos los beneficios</p>
              </div>
            </a>
          </div>

          <!-- <div class="col-md-6 col-bg-button">
            <a class="flex-center-xy t-250 visit" @click="showModalVisitaGuiada = true">
              <div>
                <p class="title">Agenda una visita guiada</p>
                <p>a una de nuestras sucursales</p>
              </div>
            </a>
          </div> -->

          <div class="col-md-6 col-bg-button d-md-none">
            <router-link class="flex-center-xy t-250 easylover" to="/easylover">
              <div>
                <p class="title easylover">Easylover</p>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </section>

    <section class="samples-section">
      <div class="container oversized-container">
        <div class="row">
          <div class="col-12 col-title">
            <h5 class="title-s-1 txt-blue txt-upp">Promociones del mes</h5>
          </div>

          <div class="col-12 col-image">
            <swiper class="swiper" :options="samplesOptions">
              <swiper-slide v-for="(hp, hproInx) in promos" :key="'hproInx-'+hproInx">
                <router-link class="placed-backg box" v-bind:style="{ backgroundImage: 'url('+hp.image+')' }" :to="'/promociones/'+hp.id">
                  <img src="public/images/shared/empty.png">
                </router-link>
              </swiper-slide>

              <div class="swiper-button-prev" slot="button-prev"></div>
              <div class="swiper-button-next" slot="button-next"></div>
            </swiper>
          </div>
        </div>
      </div>
    </section>

    <section class="samples-section facilities">
      <div class="container oversized-container">
        <div class="row">
          <div class="col-12 col-title">
            <h5 class="title-s-1 txt-blue txt-upp">Instalaciones</h5>
          </div>

          <div class="col-12 col-image">
            <swiper class="swiper" :options="samplesOptions">
              <swiper-slide v-for="(hp, hinstInx) in facilities" :key="'hinstInx-'+hinstInx">
                <div class="placed-backg box cursor-pointer" @click="setIndex(hp.id)" v-bind:style="{ backgroundImage: 'url('+hp.imageUrl+')' }">
                  <img src="public/images/pages/facilities/empty.png">

                  <div class="box-text" style="bottom: 36px;color: #fff;left: 0;position: absolute;text-align: center;width: 100%;z-index: 5;">
                      <h5>{{ hp.nombre }}</h5>
                  </div>

                </div>
              </swiper-slide>

              <div class="swiper-button-prev" slot="button-prev"></div>
              <div class="swiper-button-next" slot="button-next"></div>
            </swiper>
          </div>
        </div>
      </div>

      <CoolLightBox
        :items="facilities"
        :index="index"
        :effect="'fade'"
        loop
        @close="index = null">
      </CoolLightBox>
    </section>

    <section class="classes-section">
      <div class="container oversized-container">
        <div class="row">
          <div class="col-12 col-title">
            <h5 class="title-s-1 txt-blue txt-upp">Clases</h5>
            <p>Impartidas por profesionales que te ayudarán a lograr tus metas.</p>
          </div>

          <div class="col-12 col-gallery">
            <swiper class="swiper" :options="classesOptions">
              <swiper-slide v-for="(hc, hclassInx) in classes" :key="'hclassInx-'+hclassInx">
                <div class="col-12 px-0 class-sample-1">
                  <router-link class="placed-backg box" v-bind:style="{ backgroundImage: 'url('+hc.image+')' }" :to="'/clases/'+hc.id">
                    <img src="public/images/pages/classes/empty.png">

                    <div class="box-text">
                      <h5>{{ hc.nombre }}</h5>
                    </div>
                  </router-link>
                </div>
              </swiper-slide>

              <div class="swiper-button-prev" slot="button-prev"></div>
              <div class="swiper-button-next" slot="button-next"></div>
            </swiper>
          </div>

          <div class="col-12 col-button">
            <a class="t-250" @click="showModalClasePrueba = true">Agenda 1 clase de prueba y sin costo</a>
          </div>
        </div>
      </div>
    </section>

    <section class="blog-section">
      <div class="container oversized-container">
        <div class="row">
          <div class="col-12 col-title">
            <h5 class="title-s-1 txt-blue txt-upp">Comunicados</h5>
          </div>

          <div class="col-12 col-articles">
            <swiper class="swiper" :options="blogOptions">
              <swiper-slide v-for="(b, hbdhInx) in blog" :key="'hbdhInx-'+hbdhInx">
                <div class="col-12 px-0 article-sample-1">
                  <router-link class="t-250 box" :to="'/comunicados/'+b.id">
                    <div class="col col-image">
                      <img src="public/images/shared/empty.png" class="placed-backg" v-bind:style="{ backgroundImage: 'url('+b.image+')' }">
                    </div>

                    <div class="col col-text">
                      <h5 class="title">{{ b.titulo }}</h5>

                      <div class="descr" v-html="b.cuerpo"></div>
                    </div>
                  </router-link>
                </div>
              </swiper-slide>

              <div class="swiper-button-prev" slot="button-prev"></div>
              <div class="swiper-button-next" slot="button-next"></div>
            </swiper>
          </div>
        </div>
      </div>
    </section>

    <!-- <section class="videos-section">
      <div class="container oversized-container">
        <div class="row">
          <div class="col-12 col-title">
            <h5 class="title-s-1 txt-blue txt-upp">Videos</h5>
          </div>
        </div>
      </div>
    </section> -->

    <!-- Modal membresía por 1 día -->
    <modalMembresiaDia v-if="showModalMembresiaDia"></modalMembresiaDia>

    <!-- Modal Agenda visita guiada -->
    <modalVisitaGuiada v-if="showModalVisitaGuiada"></modalVisitaGuiada>

    <!-- Modal Agenda clase de prueba gratis -->
    <modalClasePrueba v-if="showModalClasePrueba"></modalClasePrueba>

    <sweet-modal ref="modalalert" :icon="modal.icon">
        <p style="font-weight: 500;">{{modal.msg}}</p>
        <br>
        <button @click="$refs.modalalert.close()" class="btn btn-info" style="background-color: #e37222;border-color: #e37222;width: 150px;">Aceptar</button>
    </sweet-modal>

  </div>
</template>

<script>
import modalMembresiaDia from './components/ModalMembresiaDiaComponent.vue'
import modalVisitaGuiada from './components/ModalVisitaGuiadaComponent.vue'
import modalClasePrueba from './components/ModalClasePruebaComponent.vue'
export default {
  components: {
    modalMembresiaDia, modalVisitaGuiada, modalClasePrueba
  },

  data(){
    return{
      index: null,

      showModalMembresiaDia: false,
      showModalVisitaGuiada: false,
      showModalClasePrueba: false,

      banners: [],

      promos: [],

      facilities: [],

      classes: [],

      blog: [],

      // == Carousel options ==
      bannersOptions: { // https://github.com/surmon-china/vue-awesome-swiper/issues/335
        slidesPerView: 1,
        loop: true,

        autoplay: {
          delay: 4000,
          disableOnInteraction: false
        },

        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },

      samplesOptions: {
        slidesPerView: 4,
        loop: false,

        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },

        breakpoints: {
          1200: {
            slidesPerView: 4,
            spaceBetween: 20
          },
          992: {
            slidesPerView: 3,
            spaceBetween: 10
          },
          575: {
            slidesPerView: 2,
            spaceBetween: 10
          },
          1: {
            slidesPerView: 1,
            spaceBetween: 10
          }
        }
      },

      classesOptions: {
        slidesPerView: 5,
        loop: true,

        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },

        breakpoints: {
          1400: {
            slidesPerView: 5,
            spaceBetween: 1
          },
          1200: {
            slidesPerView: 4,
            spaceBetween: 1
          },
          992: {
            slidesPerView: 3,
            spaceBetween: 1
          },
          576: {
            slidesPerView: 2,
            spaceBetween: 1
          },
          1: {
            slidesPerView: 1,
            spaceBetween: 1
          }
        }
      },

      blogOptions: {
        slidesPerView: 2,
        loop: false,

        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },

        autoplay: {
          delay: 4000,
          disableOnInteraction: false
        },

        breakpoints: {
          992: {
            slidesPerView: 2,
            spaceBetween: 15
          },
          1: {
            slidesPerView: 1,
            spaceBetween: 15
          }
        }
      },
      // == ==
      modal:{
        icon:null,
        msg:null
      }
    }
  },

  methods: {
    setIndex(id) {
      //this.index = index;

      this.$router.push('/instalaciones/'+id);
    }
  },
  watch:{

  },
  beforeMount(){
    axios.get(tools.url("/api/home")).then((response)=>{
      const { slider, promociones, instalaciones, clases, comunicados } = response.data;
      this.banners =  slider
      this.promos = promociones
      this.classes = clases
      this.blog = comunicados
      this.facilities = instalaciones
    }).catch((error)=>{
      console.error('Error al obtener la info del Home.');
    });
  }
}
</script>
