<template lang="html">
  <div class="loading-s1-component">
    <div class="box">
      <img src="public/images/shared/loading.gif" alt="">
    </div>
  </div>
</template>

<script>
export default {
}
</script>
