<template lang="html">
  <div id="facilities-page">

    <section class="container oversized-container main-section">
      <div class="row">
        <div class="col-12 col-title">
          <h5 class="title-s-1 txt-blue">Instalaciones - {{category.nombre}}</h5>
        </div>

        <!-- <div  class="col-12 col-video">
        </div> -->

        <div class="col-12 col-gallery">
          <div v-swiper="galleryOptions" class="swiper" :instanceName="'h-facilities'" :loadtheme="false">
            <div class="swiper-wrapper">
              <div class="swiper-slide" v-for="(hp, hinstInx) in category.images" :key="'hinstInx-'+hinstInx">
                <div class="placed-backg t-150 box" @click="setIndex(hinstInx)" v-bind:style="{ backgroundImage: 'url('+hp.image+')' }">
                  <img src="public/images/pages/facilities/empty.png">
                  <!-- <div class="box-text" style="bottom: 36px;color: #fff;left: 0;position: absolute;text-align: center;width: 100%;z-index: 5;">
                      <h5>{{ hp.nombre }}</h5>
                  </div> -->
                </div>
              </div>
            </div>

            <div class="swiper-button-prev" slot="button-prev"></div>
            <div class="swiper-button-next" slot="button-next"></div>
          </div>
        </div>
      </div>

      <CoolLightBox
        :items="category.images"
        :index="index"
        :effect="'fade'"
        loop
        @close="index = null">
      </CoolLightBox>
    </section>

  </div>
</template>

<script>
export default {
  data(){
    return{
    	category:{
    		name:null,
    		images:[],
    	},
      facilities: [],
      videos:[],
      
      index: null,

      // == Carousel options ==
      videosOptions: {
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },

      galleryOptions: {
        slidesPerView: 4,
        loop: false,

        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },

        breakpoints: {
          1200: {
            slidesPerView: 4,
            spaceBetween: 20
          },
          992: {
            slidesPerView: 3,
            spaceBetween: 10
          },
          575: {
            slidesPerView: 2,
            spaceBetween: 10
          },
          1: {
            slidesPerView: 1,
            spaceBetween: 10
          }
        }
      },
      // == ==
    }
  },

  methods: {
    

    setIndex(index) {
      //this.$router.push('/instalaciones/'+id);
      
    }
  },

  beforeMount(){
    axios.get(tools.url("/api/instalaciones/"+this.$route.params.id)).then((response)=>{
      this.category = response.data;
  
    
    }).catch((error)=>{
      console.error('Error al obtener la info de Instalaciones.');
    });
  }
}
</script>
