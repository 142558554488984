<template lang="html">
  <div>
    <b-modal ref="modal-membresia" modal-class="modal-s1" @hidden="destroyModal" centered hide-footer no-close-on-backdrop no-close-on-esc title="">
      <!-- Paso 1 -->
      <div v-show="step == 1">
        <div class="box-top">
          <!-- <h5 class="modal-title">¡Incríbete ya!</h5> -->
          <h5 class="modal-title">Tramita tu tarjeta y<br />descarga en línea</h5>
        </div>

        <b-form class="col-12 px-2" @submit="onSubmitRegister">
          <div class="row">
            <b-form-group class="col-12 custom-group-1" label="Agregar foto:">
              <b-form-file  v-model="registroForm.foto" accept="image/*" plain name="image" @change="changefoto"></b-form-file>
            </b-form-group> 

            <b-form-group class="col-12 custom-f-group-1"
              label="Número de socio:">
              <b-form-input size="sm"
                v-model="registroForm.numero_socio"
                type="number"
                placeholder="Número de socio"
                required
              ></b-form-input>
            </b-form-group>

            <b-form-group class="col-12 custom-f-group-1"
              label="Nombre(s):">
              <b-form-input size="sm"
                v-model="registroForm.nombre"
                type="text"
                placeholder="Nombre completo"
                required
              ></b-form-input>
            </b-form-group>

            <b-form-group class="col-12 custom-f-group-1"
              label="Apellido paterno:">
              <b-form-input size="sm"
                v-model="registroForm.apellido_paterno"
                type="text"
                placeholder="Apellido paterno"
                required
              ></b-form-input>
            </b-form-group>

            <b-form-group class="col-12 custom-f-group-1"
              label="Apellido materno:">
              <b-form-input size="sm"
                v-model="registroForm.apellido_materno"
                type="text"
                placeholder="Apellido materno"
                required
              ></b-form-input>
            </b-form-group>

            <!-- <b-form-group class="col-12 custom-f-group-1"
              label="Fecha de nacimiento:">
              <b-form-input size="sm"
                v-model="registroForm.fecha_nacimiento"
                type="date"
                placeholder="DD - MM - AAAA"
                required
              ></b-form-input>
            </b-form-group>
 -->
            <b-form-group class="col-12 custom-f-group-1"
              label="Correo electrónico:">
              <b-form-input size="sm"
                v-model="registroForm.correo"
                type="email"
                placeholder="correo@email.com"
                required
              ></b-form-input>
            </b-form-group>

            <b-form-group class="col-12 custom-f-group-1"
              label="Teléfono (10 dígitos):">
              <b-form-input size="sm"
                v-model="registroForm.telefono"
                type="text"
                placeholder="1234567890"
                minlength="10"
                maxlength="10"
                required
              ></b-form-input>
            </b-form-group>

            <!-- <b-form-group class="col-12 custom-f-group-1"
              label="Sucursal:">
              <b-form-select size="sm" required v-model="registroForm.sucursal" v-bind:class="{ 'not-filled' : !registroForm.sucursal }">
                <b-form-select-option :value="null">Selecciona tu Sucursal</b-form-select-option>
                <b-form-select-option value="a">Plaza Aleira</b-form-select-option>
                <b-form-select-option value="b">Ávila Camacho</b-form-select-option>
              </b-form-select>
            </b-form-group>

            <b-form-group class="col-12 custom-f-group-1"
              label="Membresía:">
              <b-form-select size="sm" required v-model="registroForm.membresia" v-bind:class="{ 'not-filled' : !registroForm.membresia }">
                <b-form-select-option :value="null">Selecciona el tipo de Membresía</b-form-select-option>
                <b-form-select-option value="1">Membresía 1</b-form-select-option>
                <b-form-select-option value="2">Membresía 2</b-form-select-option>
              </b-form-select>
            </b-form-group> -->

            <div class="col-12 mt-2">
              <b-button type="submit" class="w-100 btn-s-1 btn-lg blue">Inscribirme</b-button>
            </div>
          </div>
        </b-form>
      </div>

      <div v-show="step == 2">
        <div class="box-top">
          <!-- <h5 class="modal-title">¡Incríbete ya!</h5> -->
          <h5 class="modal-title">Tarjeta de beneficios</h5>

          <p class="mt-2 text-center">
            <a class="btn btn-sm btn-s-1 blue px-5" @click="step = 1;"><i class="fal fa-edit"></i> Modificar</a>
          </p>

          <hr />

          <div class="box-pdf-sample">
            <div class="row">
              <div class="col col-image">
                <div class="image" v-bind:style="{ backgroundImage: 'url('+registroForm.fotourl+')' }">
                  <img src="public/images/shared/empty.png">
                </div>
              </div>

              <div class="col col-info">
                <h6>Nombre:
                  <strong class="d-block">{{ registroForm.nombre }} {{ registroForm.apellido_paterno }} {{ registroForm.apellido_materno }}</strong>
                </h6>
               <!--  <h6>Fecha de nacimiento:
                  <strong class="d-block">{{ registroForm.fecha_nacimiento }}</strong>
                </h6> -->
                <h6>No. de socio:
                  <strong class="d-block">{{ registroForm.numero_socio }}</strong>
                </h6>
                <h6>Correo electrónico:
                  <strong class="d-block">{{ registroForm.correo }}</strong>
                </h6>
                <h6>Teléfono: 
                  <strong class="d-block">{{ registroForm.telefono }}</strong>
                </h6>
                <h6>Vigencia: 
                  <strong class="d-block">{{ registroForm.vigencia }}</strong>
                </h6>
              </div>

              <div class="col-12 col-button mt-3">
                <b-button @click="saveTarjeta" type="button" class="w-100 btn-s-1 btn-lg blue">Imprimir en PDF para recortar</b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  data(){
    return{
      step: 1,

      registroForm: {
        numero_socio:null,
        nombre: null,
        apellido_paterno: null,
        apellido_materno: null,
        fecha_nacimiento: null,
        telefono: null,
        correo: null,
        objetivo: null,
        paquete: null,
        plan_id: null,
        sucursal_id: null,
        foto:null,
        fotourl:null,
        vigencia:null

      },
      
    }
  },

  methods: {
    destroyModal(){
      setTimeout(()=>{ this.$root.show_ModalTramitaMembresia = false; }, 350);
    },

    onSubmitRegister(event) {
      event.preventDefault();

      axios.post(tools.url('/api/checktarjetas'),this.registroForm).then((response)=>{
          
            if(response.data.status == 'ok'){
              this.step = 2;
              this.registroForm.vigencia = response.data.vigencia;
            }
            else{
              alert('No se encontro registro con el número de socio ingresado');
            }
        }).catch((error)=>{
            alert('No se encontro registro con el número de socio ingresado');
        });

      /*this.$axios.post('/inscripciones', this.registroForm).then(response => {
        console.log(response.data);
          let row = response.data;

      });
      */

      

      // axios.post(tools.url("/api/inscripciones"),this.registroForm).then((response)=>{
      //     this.registroForm.nombre = null;
      //     this.registroForm.apellidos = null;
      //     this.registroForm.fecha_nacimiento = null;
      //     this.registroForm.telefono = null;
      //     this.registroForm.correo = null;
      //     this.registroForm.objetivo = null;
      //     this.registroForm.paquete = null;
      //     this.registroForm.plan_id = null;
      //     this.registroForm.sucursal_id = null;
      //
      //     alert('Registro completado!');
      //
      // }).catch((error)=>{
      //     alert('Ocurrio un error al guardar la información');
      // });
      //
      // setTimeout(()=>{ this.$root.show_ModalTramitaMembresia = false; }, 350);
    },
    saveTarjeta(){
        var formData = new FormData();
        if (typeof jQuery('input[name="image"]')[0].files[0] !== 'undefined') {
            formData.append("image", jQuery('input[name="image"]')[0].files[0]);
        }
        formData.append("nombre", this.registroForm.nombre);
        formData.append("apellido_paterno", this.registroForm.apellido_paterno);
        formData.append("apellido_materno", this.registroForm.apellido_materno);
       

        formData.append("numero_socio", this.registroForm.numero_socio);
        formData.append("correo", this.registroForm.correo);
        formData.append("telefono", this.registroForm.telefono);

        axios.post(tools.url('/api/tarjetas'),formData).then((response)=>{
          
            window.open(response.data.documentUrl,'_blank');
        }).catch((error)=>{
            
        });

    },
    getPlanes(){
      axios.get(tools.url("/api/planes")).then((response)=>{
        this.plans = response.data
      }).catch((error)=>{
        console.error('Error al obtener la info.');
      });
    },
    'changefoto'(e){
        const file = e.target.files[0];
        this.registroForm.fotourl = URL.createObjectURL(file);
         
    }
  },
  mounted(){
      this.getPlanes();
  },
  watch: {
   '$root.show_ModalTramitaMembresia': function(value, oldValue){ // Mostrar u ocultar este modal/componente
      if(value){
        this.$refs['modal-membresia'].show();
      }else{
        this.$refs['modal-membresia'].hide();
      }
    }
    
  },
}
</script>
