<template lang="html">
  <div id="jobs-page">

    <section class="container oversized-container main-section">
      <div class="row">
        <div class="col-12 col-title">
          <h5 class="title-s-1 txt-blue">Bolsa de trabajo</h5>
        </div>

        <div class="col-md-6 col-lg-4 col-xl-3 col-job" v-for="(x, jbInx) in trabajos" :key="'jbInx-'+jbInx">
          <div class="box">
            <div class="box-image">
              <img class="placed-backg" src="public/images/shared/empty.png" v-bind:style="{ backgroundImage: 'url('+x.image+')' }">
            </div>

            <div class="box-info">
              <h5 class="title">{{ x.titulo }}</h5>
              <h6 class="subtitle">{{ x.subtitulo }}</h6>

              <!-- <div class="descr" v-html="x.cuerpo"></div> -->

              <div class="box-buttons">
                <router-link class="btn btn-block btn-s-1 blue" :to="'/bolsa-de-trabajo/'+x.id">Ver vacante</router-link>
              </div>
            </div>
          </div>
        </div>

      </div>
    </section>

  </div>
</template>

<script>
export default {
  data(){
    return{
      trabajos: [],
    }
  },

  beforeMount(){
    axios.get(tools.url("/api/bolsatrabajos")).then((response)=>{
      this.trabajos = response.data
    }).catch((error)=>{
      console.error('Error al obtener la info de Bolsa de trabajo.');
    });
  },
}
</script>
