require('./bootstrap');

import Vue from 'vue';
//Rutas del website
import Router from './router.js';

//Librerias globales
import Library from './libs.js';
Vue.use(Library);

//Componentes del website
import components from './components/components.js';
Vue.use(components);

window.Vue=Vue;

//Instancia principal
const app = new Vue({
  el: '#app',
  router:Router,

  data(){
    return{
      sucursales: [],

      show_ModalSeleccionaPlan: false,
      modalSeleccionaPlan_plan_id:null,

      show_ModalTramitaMembresia: false,
      show_ModalAfiliarMarcaEmpresa: false,
    }
  },
  methods:{
      opensModalSeleccionaPlan(plan_id){
          this.show_ModalSeleccionaPlan = true;
          this.modalSeleccionaPlan_plan_id = plan_id;
      }
  },
  beforeMount(){
    axios.get(tools.url("/api/sucursales")).then((response)=>{
      this.sucursales = response.data;
    }).catch((error)=>{
      console.error('Error al obtener la info sucursales.');
    });
  },
});
