<template lang="html">
  <div id="classes-page">

    <section class="container oversized-container">
      <div class="row">
        <div class="col-12 col-title">
          <h5 class="title-s-1 txt-blue">Clases</h5>
        </div>
      </div>

      <div class="row box-classes">
        <div class="col-sm-6 col-lg-4 col-xl-3 p-1 class-sample-1" v-for="(cc, cclassInx) in classes" :key="'cclassInx-'+cclassInx">
          <router-link class="placed-backg box" v-bind:style="{ backgroundImage: 'url('+cc.image+')' }" :to="'/clases/'+cc.id">
            <img src="public/images/pages/classes/empty.png">

            <div class="box-text">
              <h5>{{ cc.nombre }}</h5>
            </div>
          </router-link>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
  data(){
    return{
      classes: [],
    }
  },

  beforeMount(){
    axios.get(tools.url("/api/clases")).then((response)=>{
      this.classes = response.data;
    }).catch((error)=>{
      console.error('Error al obtener la info de Clases.');
    });
  }
}
</script>
