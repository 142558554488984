<template lang="html">
  <div id="branch-office-page">

    <div v-if="isLoading">
      <loading-s1-component></loading-s1-component>
    </div>

    <div v-else>
      <section class="container oversized-container basic-info-section">
        <div class="row">
          <div class="col-12 col-title">
            <h2 class="title-s-1 txt-blue">Sucursales</h2>
          </div>

          <div class="col-lg-8 col-image">
            <div class="placed-backg image" v-bind:style="{ backgroundImage: 'url('+content.image+')' }"></div>
          </div>

          <div class="col-lg-4 col-info">
            <h1 class="mb-2 mb-lg-4 name">{{ content.nombre }}</h1>

            <h5 class="subtitle">Dirección:</h5>
            <p v-html="content.direccion"></p>

            <h5 class="mt-3 subtitle">Teléfono:</h5>
            <p>
              <a :href="content.telefono">{{ content.telefono }}</a>
            </p>

            <h5 class="mt-3 subtitle">Horarios:</h5>
            <div v-html="content.horarios"></div>

            <p class="mt-2 mt-lg-3">
              <a class="w-100 rounded-0 btn btn-s-1 green" target="_blank" :href="'https://wa.me/'+content.whatsapp_url">
                <i class="fab fa-whatsapp"></i> Mensaje a la sucursal
              </a>
            </p>
          </div>
        </div>
      </section>

      <section class="container oversized-container map-section">
        <!-- <div v-html="content.google_map"></div> -->
        <iframe :src="content.google_map" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      </section>

      <!-- <section class="container oversized-container gallery-section">
        <div class="row">
          <div class="col-6 col-md-4 col-lg-3 col-image" v-for="(image, scInx) in items" :key="'scInx'+scInx">
            <div class="t-150 placed-backg img" v-bind:style="{ backgroundImage: 'url('+image+')'}" @click="setIndex(scInx)">
              <img class="w-100" src="public/images/shared/empty.png">
            </div>
          </div>
        </div>
      </section> -->

      <CoolLightBox
        :items="items"
        :index="index"
        :effect="'fade'"
        loop
        @close="index = null">
      </CoolLightBox>
    </div>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      isLoading: false,

      items: [],
      content:[],
      index: null
    }
  },

  methods: {
    getSucursal(){
      this.isLoading = true;
      axios.get(tools.url("/api/sucursales/"+this.$route.params.id)).then((response)=>{
        this.content = response.data

        this.isLoading = false;
      }).catch((error)=>{
        console.error('Error al obtener la info de Sucursal.');
      });
    },

    setIndex(index) {
      this.index = index;
    },
  },

  beforeMount(){
    this.getSucursal();
  },

  watch: {
   '$route' (to, from){
      this.getSucursal();
    }
  }
}
</script>
