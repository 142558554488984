<template lang="html">
  <div id="article-page">

    <div class="container content-section">
      <div class="row">
        <div class="col-12 col-title">
          <h5 class="title-s-1 txt-blue">Comunicados</h5>
        </div>
      </div>

      <div class="row">
        <!-- Artículo actual -->
        <div class="col-lg-12 col-article">
          <img class="float-image" :src="noticia.image">

          <!-- <div class="mb-3 box-image">  </div> -->

          <h1 class="title">{{ noticia.titulo }}</h1>
          <div class="descr float" v-html="noticia.cuerpo"></div>
        </div>
        <!--  -->

        <!-- Artículos -->
        <!-- <div class="col-lg-4 col-blog">
          <div class="row">
            <div class="col-12 col-article-sample-1 sample-2" v-for="(blog, arInx) in blogs" :key="'ar-'+arInx">
              <router-link class="box-article" :to="'/comunicados/'+blog.id">
                <div class="placed-backg box-image" v-bind:style="{ backgroundImage: 'url('+blog.image+')' }">
                  <img src="public/images/shared/empty.png">
                </div>

                <div class="box-descr">
                  <h5>{{ blog.titulo }}</h5>
                </div>
              </router-link>
            </div>
          </div>
        </div> -->
        <!--  -->
      </div>
    </div>

  </div>
</template>

<script>
export default {
  data(){
    return{
      noticia: [],
      blogs: [],
      id: null,
    }
  },

  methods: {
    getComunicado: function(){
      axios.get(tools.url("/api/comunicados/"+this.$route.params.id)).then((response)=>{
        this.noticia = {};
        this.noticia = response.data;
      }).catch((error)=>{
        console.error('Error al obtener la info del comunicado.');
      });
    },

    getComunicados: function(){
      axios.get(tools.url("/api/comunicados/")).then((response)=>{
        this.blogs = [];
        this.blogs = response.data;
      }).catch((error)=>{
        console.error('Error al obtener la info de comunicados.');
      });
    },
  },

  beforeMount(){
    this.getComunicado();
    this.getComunicados();
  },

  watch: {
   '$route' (to, from){
     this.getComunicado();
     this.getComunicados();
    }
  }
}
</script>
