<template lang="html">
  <div id="easylovers-page">

    <section class="container oversized-container main-section">
      <div class="row">
        <div class="col-12 col-title">
          <h5 class="title-s-1 txt-orange">Easy lover</h5>

          <p class="sub">
            Conoce todos los beneficios para los que amarás a Easyfit
          </p>
        </div>

        <div class="col-12 col-info">
          <div class="row">
            <div class="col-12 col-lg-6 article-sample-1" v-for="(b, elovInx) in blog" :key="'elovInx-'+elovInx">
              <div class="t-250 box">
                <div class="col col-image">
                  <img src="public/images/shared/empty.png" class="placed-backg" v-bind:style="{ backgroundImage: 'url('+b.image+')' }">
                </div>

                <div class="col col-text">
                  <h5 class="title">{{ b.titulo }}</h5>

                  <div class="descr" v-html="b.cuerpo"></div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </section>

  </div>
</template>

<script>
export default {
  data(){
    return{
      blog: [],
    }
  },

  beforeMount(){
    axios.get(tools.url("/api/easylover")).then((response)=>{
      this.blog = response.data
    }).catch((error)=>{
      console.error('Error al obtener la info de Easylover.');
    });
  }
}
</script>
