<template lang="html">
  <div>
    <!-- Modal Tramite y descarga en Linea -->
    <b-modal ref="modal-membresia-dia" modal-class="modal-s1" @hidden="destroyModal" centered hide-footer no-close-on-backdrop no-close-on-esc title="">
      <div class="box-top">
        <h5 class="modal-title">¡Incríbete por un día!</h5>
      </div>

      <b-form class="col-12 px-2" @submit="onSubmitRegister">
        <div class="row">
          <b-form-group class="col-12 custom-f-group-1"
            label="Nombre:">
            <b-form-input size="sm"
              v-model="registroForm.nombre"
              type="text"
              placeholder="Nombre(s)"
              required
            ></b-form-input>
          </b-form-group>

          <b-form-group class="col-12 custom-f-group-1"
            label="Apellidos:">
            <b-form-input size="sm"
              v-model="registroForm.apellidos"
              type="text"
              placeholder="Apellidos"
              required
            ></b-form-input>
          </b-form-group>

          <b-form-group class="col-12 custom-f-group-1"
            label="Fecha de nacimiento:">
            <b-form-input size="sm"
              v-model="registroForm.fecha_nacimiento"
              type="date"
              placeholder="DD - MM - AAAA"
              required
            ></b-form-input>
          </b-form-group>

          <b-form-group class="col-12 custom-f-group-1"
            label="Correo electrónico:">
            <b-form-input size="sm"
              v-model="registroForm.correo"
              type="email"
              placeholder="correo@email.com"
              required
            ></b-form-input>
          </b-form-group>

          <b-form-group class="col-12 custom-f-group-1"
            label="Teléfono (10 dígitos):">
            <b-form-input size="sm"
              v-model="registroForm.telefono"
              type="text"
              placeholder="1234567890"
              minlength="10"
              maxlength="10"
              required
            ></b-form-input>
          </b-form-group>

          <div class="col-12 mt-2">
            <b-button type="submit" class="w-100 btn-s-1 btn-lg blue">Inscribirme</b-button>
          </div>
        </div>
      </b-form>
    </b-modal>
   
    <!--  -->
  </div>
</template>

<script>
export default {
  data(){
    return{
      registroForm: {
        nombre: null,
        apellidos: null,
        fecha_nacimiento: null,
        email: null,
        telefono: null,
        plan_id: null,
        sucursal_id: null,
        tipo:'1 dia'
      },
   
    }
  },

  methods: {
    destroyModal(){
      setTimeout(()=>{ this.$parent.showModalMembresiaDia = false; }, 350);
    },

    onSubmitRegister(event) {
      event.preventDefault();

       axios.post(tools.url("/api/inscripciones"),this.registroForm).then((response)=>{
              this.registroForm.nombre = null;
              this.registroForm.apellidos = null;
              this.registroForm.fecha_nacimiento = null;
              this.registroForm.telefono = null;
              this.registroForm.correo = null;
              this.registroForm.objetivo = null;
              this.registroForm.paquete = null;
              this.registroForm.plan_id = null;
              this.registroForm.sucursal_id = null;
              this.$parent.showModalMembresiaDia = false;
             
              this.$parent.modal.icon = 'success';
              this.$parent.modal.msg = 'Gracias por tu interés , nos comunicaremos contigo para confirmar tu acceso por 1 dia.';
              this.$parent.$refs.modalalert.open();
          }).catch((error)=>{
              this.$parent.modal.icon = 'success';
              this.$parent.modal.msg = 'Ocurrio un error al guardar la información';
              this.$parent.$refs.modalalert.open();
              //alert('Ocurrio un error al guardar la información');
        });



      //setTimeout(()=>{ this.$parent.showModalMembresiaDia = false; }, 350);
    },
  },

  mounted(){
    this.$refs['modal-membresia-dia'].show();
  }
}
</script>
