<template lang="html">
  <div id="card-use-page">

    <section class="container oversized-container main-section">
      <div class="row justify-content-center">
        <div class="col-12 col-title">
          <h5 class="title-s-1 txt-blue">Tarjeta de beneficios</h5>
        </div>

        <div class="col-md-6 col-lg-4 col-card">
          <div class="box">
            <div class="num">01</div>
            <div class="inside">
              <span>Solicita tu tarjeta en tu sucursal de origen (vía whatsapp, vía correo o en recepción de sucursal)</span>
            </div>
          </div>
        </div>

        <div class="col-md-6 col-lg-4 col-card">
          <div class="box">
            <div class="num">02</div>
            <div class="inside">
              <span>Conoce los más de 160 beneficios que tendrás con ella en nuestra página web</span>
            </div>
          </div>
        </div>

        <div class="col-md-6 col-lg-4 col-card">
          <div class="box">
            <div class="num">03</div>
            <div class="inside">
              <span>Revisa los términos y condiciones de cada marca para poder usar el beneficio</span>
            </div>
          </div>
        </div>

        <div class="col-md-6 col-lg-4 col-card">
          <div class="box">
            <div class="num">04</div>
            <div class="inside">
              <span>Para hacer válidas las promociones en tus marcas favoritas deberás presentar tu tarjeta de beneficios y una identificación oficial</span>
            </div>
          </div>
        </div>

        <div class="col-md-6 col-lg-4 col-card">
          <div class="box">
            <div class="num">05</div>
            <div class="inside">
              <span>La tarjeta se actualizará cada mes, puedes pedirla al inicio de cada mes en tu sucursal, es importante estar al corriente en los pagos de membresía</span>
            </div>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
}
</script>
