<template lang="html">
  <div id="promo-description-page">

    <section class="description-section">
      <div class="placed-backg class-image" v-bind:style="{ backgroundImage: 'url('+row.image+')' }"></div>

      <div class="container oversized-container">
        <div class="row">
          <div class="col-md-6 col-lg-7 col-images"></div>

          <div class="col-md-6 col-lg-5 col-info">
            <h1 class="mb-4 mb-md-5 title"><span>{{ row.titulo }}</span></h1>


            <div class="descr">{{ row.subtitulo }}</div>


            <div class="descr" v-html="row.descripcion"></div>

            <h5 class="mt-4 subcategory">Ciudad</h5>
            <div class="descr" v-html="row.ciudad"></div>

            <h5 class="mt-4 subcategory">Vigencia</h5>
            <div class="descr">{{ row.vigencia }}</div>

          </div>
        </div>
      </div>
    </section>


  </div>
</template>

<script>
export default {
  data(){
    return{
      row:{

      }
    }
  },

  methods: {


    getRow(){
      axios.get(tools.url("/api/promociones/"+this.$route.params.id)).then((response)=>{
        this.row = response.data;
      }).catch((error)=>{
        console.error('Error al obtener la info de la promocione.');
      });
    },

  },

  beforeMount(){
    this.getRow();
  },

  watch: {
   '$route' (to, from){
      this.getRow();

    }
  }
}
</script>
