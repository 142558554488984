<template lang="html">
  <div id="benefits-page">

    <section class="placed-backg banner-section" v-bind:style="{ backgroundImage: 'url('+seccionbeneficios.image+')' }">
      <div class="container">
        <div class="row flex-center-xy">
          <div class="col-lg-10">
            <div class="box-search-benefit">
              <div class="box">
                <!-- <h1 class="title">Busca beneficios</h1> -->
                <p class="txt-top" v-html="seccionbeneficios.body">

                </p>

                <b-form @submit="onSubmitSearch">
                  <b-form-input class="col"
                    v-model="searchForm.keywords"
                    type="text"
                    placeholder="Buscar beneficios..."
                    autofocus
                    required
                  ></b-form-input>

                  <b-form-select required v-model="searchForm.state">
                    <b-form-select-option value="1">Guadalajara</b-form-select-option>
                    <b-form-select-option value="2">León</b-form-select-option>
                  </b-form-select>

                  <b-button type="submit" class="btn-search">Buscar</b-button>
                </b-form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- <section class="container oversized-container info-section">
      <p>
        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla unt mollit anim id est laborum.
      </p>
      <br />

      <ul>
        <li>Cing elit, sed do eiusmod tempor incididunt.</li>
        <li>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt</li>
      </ul>
    </section> -->

    <section class="container oversized-container buttons-section">
      <div class="row">
        <div class="col-sm-6 col-lg-3 col-button">
          <div class="t-250 btn-big blue" @click="$root.show_ModalTramitaMembresia = true;">
            <div class="inside">
              Tramita tu tarjeta y descarga en línea
            </div>
          </div>
        </div>

        <div class="col-sm-6 col-lg-3 col-button">
          <router-link class="t-250 btn-big orange" to="/como-usar-tu-tarjeta">
            <div class="inside">
              ¿Cómo usar tu tarjeta?
            </div>
          </router-link>
        </div>

        <div class="col-sm-6 col-lg-3 col-button">
          <router-link class="t-250 btn-big green" to="/beneficios/preguntas-frecuentes">
            <div class="inside">
              Preguntas Frecuentes
            </div>
          </router-link>
        </div>

        <div class="col-sm-6 col-lg-3 col-button">
          <div class="t-250 btn-big pink" @click="$root.show_ModalAfiliarMarcaEmpresa = true;">
            <div class="inside">
              Afília tu Marca o Empresa
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="promotions-section">
      <div class="container oversized-container states-container">
        <div class="row no-gutters">
          <div class="col-6 col-md-4 col-lg-3 col-state">
            <a v-bind:class="{ 'active' : filter.state == 'guadalajara' }" @click="filter.state = 'guadalajara'; filters()">Guadalajara</a>
          </div>
          <div class="col-6 col-md-4 col-lg-3 col-state">
            <a v-bind:class="{ 'active' : filter.state == 'leon' }" @click="filter.state = 'leon'; filters()">León</a>
          </div>
        </div>
      </div>

      <div class="categories-container">
        <div class="container oversized-container">
          <b-form-group v-slot="{ ariaDescribedby }">
            <b-form-radio-group v-model="filter.category" :aria-describedby="ariaDescribedby" name="radio-sub-component" class="box-categories">
              <b-form-radio v-for="(value, index) in categories" :key="index" :value="value.id">
                <a class="btn-category">{{value.nombre}}</a>
              </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
      </div>

      <div class="results-container">
        <div class="container oversized-container">
          <div class="row justify-content-center">
            <div class="col-12 col-title">
              <h5 class="title-s-1 txt-blue">Beneficios</h5>
            </div>

            <div class="col-12 col-xl-10 col-results">
              <div class="box" v-for="(value, prInx) in beneficios" :key="'prInx-'+prInx">
                <div class="col-lg-5 col-image">
                  <div class="placed-backg image" v-bind:style="{ backgroundImage: 'url('+value.image+')' }"></div>
                </div>

                <div class="col-lg-7 col-info">
                  <h5 class="title">{{ value.titulo  }}</h5>
                  <h6 class="promo">{{ value.subtitulo }}</h6>

                  <div class="descr" v-html="value.cuerpo">
                  </div>

                  <!-- <h6 class="validity-promo">Vigencia de la promoción: {{ value.vigencia }}</h6> -->
                  <!-- <h6 class="url-promo"><a target="_blank" href="#">www.sitioweb.com.mx</a></h6> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Modal Afilia tu empresa -->
    <modalAfiliarEmpresa v-if="showModalAfiliar"></modalAfiliarEmpresa>
  </div>
</template>

<script>
export default {
  data(){
    return{
      showModalAfiliar: false,

      searchForm: {
        state: 1,
        keywords: null,
      },

      filter: {
        state: 'guadalajara',
        category: 1,
      },

      beneficios: [],
      content:[],
      categories:[],
      seccionbeneficios:{}
    }
  },

  methods: {
    getBeneficios: function(){
      axios.get(tools.url("/api/beneficios")).then((response)=>{
        this.beneficios = response.data;
        this.content = response.data;
      }).catch((error)=>{
        console.error('Error al obtener la info de Beneficios.');
      });
    },

    onSubmitSearch(event) {
      event.preventDefault();
      this.$router.push({ path: '/beneficios/resultados', query: {state: this.searchForm.state, keywords: this.searchForm.keywords}});
    },

    filters(){
       this.beneficios = this.content.filter(x => x.ciudad == this.filter.state)
       this.beneficios = this.beneficios.filter(x => x.category_id == this.filter.category)
    },

    getCategories(){
      axios.get(tools.url("/api/categories")).then((response)=>{
        this.categories = response.data;
        this.filter.category = this.categories[0].id;
      }).catch((error)=>{
        console.error('Error al obtener la info de Beneficios.');
      });

      // this.$axios.get('/categories', {headers: {Accept: "application/json"}}).then(response => {
      //     this.categories = response.data;
      //     this.filter.category = this.categories[0].id;
      // },);
    },
    getBeneficiosSeccion(){
        axios.get(tools.url("/api/seccionbeneficios")).then((response)=>{
            this.seccionbeneficios = response.data;

          }).catch((error)=>{
            console.error('Error al obtener la info de Beneficios.');
          });
    }
  },

  beforeMount(){
    this.getBeneficios();
    this.getBeneficiosSeccion();
  },

  mounted(){
    this.getCategories();
  },

  watch: {
    'filter.category'(newValue) {
      this.filters()
    }
  },
}
</script>
