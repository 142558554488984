<template lang="html">
  <div id="plans-page">

    <section class="container main-section">
      <div class="row">
        <div class="col-12 col-title">
          <h5 class="title-s-1 txt-blue">Planes</h5>
          <h6 class="subtext">¡Elige alguno de nuestros planes con precios accesibles!</h6>
        </div>
      </div>

      <div class="row justify-content-center row-plans">
        <div class="col-md-6 col-lg-4 col-plan orange" v-for="(plan, index) in plans" :key="index">
          <div class="box-plan">
            <div class="box-con">
              <h5 class="plan-name">{{ plan.nombre }}</h5>

              <!-- <div class="box-descr-1">
                <p>
                  {{ plan.subtitulo }}
                </p>
              </div> -->

              <div class="box-from">
                *Desde
              </div>

              <h4 class="mb-4 price">${{plan.precio}}*</h4>

              <!-- <div class="box-descr-1" v-html="plan.inscripcion"></div> -->

              <div class="box-btns">
                <!-- <a class="btn-select" @click="$root.show_ModalSeleccionaPlan = true;">SELECCIONAR</a> -->
                <a class="btn-select" @click="$root.opensModalSeleccionaPlan(plan.id)">SELECCIONAR</a>
              </div>
            </div>

            <hr class="hr-sep" />

            <div class="box-con">
              <div class="box-conditions">
                <h6 class="mb-2">CONDICIONES</h6>
                <div v-html="plan.condiciones"></div>

                <h6 class="mt-4 mb-2">BENEFICIOS INCLUIDOS</h6>
                <div v-html="plan.beneficios"></div>
              </div>
            </div>
          </div>
        </div>



      </div>
    </section>

  </div>
</template>

<script>
export default {
  data(){
    return{
      plans: [],
    }
  },

  beforeMount(){
    axios.get(tools.url("/api/planes")).then((response)=>{
      this.plans = response.data
    }).catch((error)=>{
      console.error('Error al obtener la info.');
    });
  }
}
</script>
