<template lang="html">
  <div id="concept-page-v2">

    <section class="container oversized-container main-section">
      <div class="row">
        <div class="col-12 col-title">
          <h5 class="title-s-1 txt-blue">Concepto</h5>
        </div>
      </div>

      <div class="results-container">
        <div class="container oversized-container">
          <div class="row justify-content-center">
            <div class="col-12 col-xl-10 col-results">
              <div class="box" v-for="(value, imgcInx) in gallery" :key="'imgcInx'+imgcInx">
                <div class="col-lg-5 col-image">
                  <div class="placed-backg image" v-bind:style="{ backgroundImage: 'url('+value.image+')' }"></div>
                </div>

                <div class="col-lg-7 col-info">
                  <h5 class="title">{{ value.titulo  }}</h5>
                  <!-- <h6 class="promo">{{ value.subtitulo }}</h6> -->

                  <div class="descr" v-html="value.descripcion">
                  </div>

                  <!-- <h6 class="validity-promo">Vigencia de la promoción: {{ value.vigencia }}</h6> -->
                  <!-- <h6 class="url-promo"><a target="_blank" href="#">www.sitioweb.com.mx</a></h6> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
  data(){
    return{
      gallery: [],

      modalInfo: {
        titulo: null,
        descripcion: null
      }
    }
  },

  methods: {
    openModal(titulo, descripcion){
       this.modalInfo = { // DESCOMENTAR ESTO
         titulo, descripcion
       };

      this.$refs['modal-concepto'].show();
    }
  },

  beforeMount(){
    axios.get(tools.url("/api/concepto")).then((response)=>{
      this.gallery = response.data
    }).catch((error)=>{
      console.error('Error al obtener la info de Concepto.');
    });
  },

  mounted(){
  }
}
</script>
