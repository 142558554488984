<template lang="html">
  <header id="header">
    <div class="header-content">
      <b-navbar toggleable="lg" type="light" variant="light">
        <div class="container oversized-container">
          <b-navbar-brand to="/">
            <img src="public/images/logo.svg" alt="Easy Fit">
          </b-navbar-brand>

          <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

          <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav>

            </b-navbar-nav>

            <b-navbar-nav class="ml-auto">
              <!-- <b-nav-item class="nav-simple nav-home" to="/">Inicio</b-nav-item> -->
              <b-nav-item class="nav-simple" to="/planes">Planes</b-nav-item>

              <b-nav-item-dropdown class="nav-simple" left>
                <template #button-content>
                  <span>Sucursales</span>
                </template>
                <!-- <li><a class="dropdown-item location">sucursal</a></li> -->
                <b-dropdown-item  v-for="(b, shInx) in $root.sucursales" :key="'shInx-'+shInx" :to="'/sucursales/'+b.id">{{ b.nombre }}</b-dropdown-item>
              </b-nav-item-dropdown>

              <b-nav-item class="nav-simple" to="/instalaciones">Instalaciones</b-nav-item>
              <b-nav-item class="nav-simple" to="/clases">Clases</b-nav-item>
              <b-nav-item class="nav-simple" to="/beneficios">Beneficios</b-nav-item>

              <b-nav-item-dropdown class="nav-simple" left>
                <template #button-content>
                  <span>Nosotros</span>
                </template>
                <b-dropdown-item to="/preguntas-frecuentes">Preguntas frecuentes</b-dropdown-item>
                <b-dropdown-item to="/concepto">Concepto</b-dropdown-item>
                <b-dropdown-item to="/bolsa-de-trabajo">Bolsa de trabajo</b-dropdown-item>
                <b-dropdown-item to="/atencion-a-socios">Atención a socios</b-dropdown-item>
              </b-nav-item-dropdown>

              <!-- <b-nav-item class="nav-simple" to="/comunicados">Comunicados</b-nav-item> -->
              <b-nav-item class="nav-simple nav-green" to="/contacto">Contacto <i class="far fa-envelope icon"></i></b-nav-item>

              <li class="nav-item nav-simple d-lg-none">
                <div class="nav-link"><hr class="my-0" /></div>
              </li>

              <li class="nav-item nav-simple d-md-none">
                <a class="nav-link f-w-600 txt-green" @click="$root.opensModalSeleccionaPlan(null)">Tramita tu membresía</a>
              </li>
              <li class="nav-item nav-simple d-md-none">
                <router-link class="nav-link f-w-600 txt-blue" to="/planes">Escoge tu plan</router-link>
              </li>
              <li class="nav-item nav-simple d-md-none">
                <router-link class="nav-link f-w-600 txt-pink" to="/easylover">Easylover</router-link>
              </li>

              <li class="nav-item nav-networks">
                <div class="nav-link">
                  <!-- <router-link to="/contacto"><i class="far fa-envelope"></i></router-link> -->
                  <a target="_blank" href="https://web.facebook.com/EASYFITGDL"><i class="fab fa-facebook-square"></i></a>
                  <a target="_blank" href="https://www.instagram.com/easyfit_oficial/"><i class="fab fa-instagram"></i></a>
                </div>
              </li>
            </b-navbar-nav>
          </b-collapse>
        </div>
      </b-navbar>

      <div class="box-fixed-buttons">
        <section class="sp-buttons-section">
          <div class="container">
            <div class="row">
              <div class="col-sm-4 col-button">
                <a class="t-250 membership" @click="$root.opensModalSeleccionaPlan(null)">Tramita tu membresía</a>
              </div>

              <div class="col-sm-4 col-button">
                <router-link class="t-250 plan" to="/planes">Escoge tu plan</router-link>
              </div>

              <div class="col-sm-4 col-button">
                <router-link class="t-250 easylovers" to="/easylover">Easylover</router-link>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>

    <!-- Modal Selecciona tu plan -->
    <modal-selecciona-plan></modal-selecciona-plan>

    <!-- Modal Tramita tu membresía -->
    <modal-tramita-membresia></modal-tramita-membresia>

    <!-- Modal Afilia tu marca o empresa -->
    <modal-afilia-marca-empresa></modal-afilia-marca-empresa>

  </header>
</template>

<script>
export default {
   data(){
    return{
    }
  },

  methods: {
    addCSSClass(){
      // console.log('current name', this.$route.name);
      let specialPages = ['homePage', 'benefitsPage', 'clasePage', 'promoDescPage'];

      if( specialPages.includes(this.$route.name) ){
        document.getElementById('app').classList.remove("extra-padding-top");
      }
      else{
        document.getElementById('app').classList.add("extra-padding-top");
      }
    }
  },

  mounted(){
    this.addCSSClass();
  },

  watch: {
   '$route' (to, from){
      this.addCSSClass();
    }
  }
}
</script>
