<template lang="html">
  <div id="benefits-page" class="benefits-results-page">

    <section class="placed-backg search-section banner-section">
      <div class="container">
        <div class="row flex-center-xy">
          <div class="col-lg-11 col-xl-10">
            <div class="box-search-benefit">
              <div class="box">
                <h1 class="title">Busca beneficios</h1>

                <b-form @submit="onSubmit">
                  <b-form-input class="col"
                    v-model="searchForm.keywords"
                    type="text"
                    placeholder="Buscar beneficios..."
                    autofocus
                    required
                  ></b-form-input>

                  <b-form-select required v-model="searchForm.state">
                    <b-form-select-option value="1">Guadalajara</b-form-select-option>
                    <b-form-select-option value="2">León</b-form-select-option>
                  </b-form-select>

                 <!--  <b-form-select class="select-category" v-model="searchForm.category">
                    <b-form-select-option :value="null">Todas las categorías</b-form-select-option>
                    <b-form-select-option value="1">Categoría 1</b-form-select-option>
                    <b-form-select-option value="2">Categoría 2</b-form-select-option>
                  </b-form-select> -->

                  <b-button type="submit" class="btn-search">Buscar</b-button>
                </b-form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="promotions-section">
      <div class="pt-2 results-container">
        <div class="container oversized-container">
          <div class="row justify-content-center">
            <div class="col-12 col-title">
              <h5 class="title-s-1 txt-blue">Promociones</h5>
            </div>

            <div class="col-12 col-xl-10 col-results">
              <div class="box" v-for="(promo, prInx) in promos" :key="'prInx-'+prInx">
                <div class="col-lg-5 col-image">
                  <div class="placed-backg image" v-bind:style="{ backgroundImage: 'url('+promo.image+')' }"></div>
                </div>

                <div class="col-lg-7 col-info">
                  <h5 class="title">{{promo.titulo}}</h5>
                  <h6 class="promo">{{ promo.subtitulo }}</h6>

                  <div class="descr" v-html="promo.cuerpo">
                   
                  </div>

                  <!-- <h6 class="validity-promo">Vigencia de la promoción: 16/12/2022</h6>
                  <h6 class="url-promo"><a target="_blank" href="#">www.sitioweb.com.mx</a></h6> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
  data(){
    return{
      searchForm: {
        category: null,
        state: 1,
        keywords: null,
      },

      promos: [
       /* { id: 1, imgUrl: 'public/images/pages/benefits/promo-1.jpg', promo: '10% En total de compra' },
        { id: 2, imgUrl: 'public/images/pages/benefits/promo-2.jpg', promo: '5% En total de compra' },
        { id: 3, imgUrl: 'public/images/pages/benefits/promo-3.jpg', promo: '20% En total de compra entre semana' }*/,
      ],
    }
  },

  methods: {
    onSubmit(event) {
      event.preventDefault();

      this.$router.push(
        { path: '/beneficios/resultados',
          query: {state: this.searchForm.state, keywords: this.searchForm.keywords, category: this.searchForm.category }}
        );
      this.searchProducts();
    },
    searchProducts(){
        axios.post(tools.url('/api/beneficiosbuscar'),{keywords:this.searchForm.keywords,state:this.searchForm.state,category:this.searchForm.category}).then((response)=>{
          this.promos = response.data;
        }).catch((error)=>{
          console.log(error);
        })
    },

  },
  mounted(){
      if (this.$route.query.keywords) {
          this.searchForm.keywords = this.$route.query.keywords;
          this.searchForm.state = this.$route.query.state;
          this.searchForm.category = this.$route.query.category;
          this.searchProducts();
      }
      
  }
}
</script>
