<template lang="html">
  <div id="blog-page">

    <section class="container oversized-container articles-section">
      <div class="row">
        <div class="col-12 col-title">
          <h5 class="title-s-1 txt-blue">Comunicados</h5>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-6 col-lg-4 col-article-sample-1" v-for="(blog, index) in blogs" :key="index">
          <router-link class="box-article" :to="'/comunicados/'+blog.id">
            <div class="placed-backg box-image" v-bind:style="{ backgroundImage: 'url('+blog.image+')' }">
              <img src="public/images/shared/empty.png">
            </div>

            <div class="box-descr">
              <h5>{{ blog.titulo }}</h5>
              <div class="descr" v-html="blog.cuerpo"></div>
            </div>
          </router-link>
        </div>

      </div>

      <!-- <div class="row mt-3">
        <div class="col-12 col-pagination-sample-1">
          <b-pagination-nav :link-gen="linkGen" :number-of-pages="10" use-router></b-pagination-nav>
        </div>
      </div> -->
    </section>

  </div>
</template>

<script>
export default {
  data(){
    return{
      formSearch: {
        keywords: null
      },

      blogs: [],
    }
  },

  methods: {
    onSubmit(event) {
      event.preventDefault();
    },

    getComunicados: function(){
      axios.get(tools.url("/api/comunicados/")).then((response)=>{
        this.blogs = response.data
      }).catch((error)=>{
        console.error('Error al obtener la info de Comunicados.');
      });
    },

    linkGen(pageNum) {
      return {
        name: 'comunicados',
        query: { keywords: this.$route.query.keywords, page: pageNum }
      }
    },

    getBlogs: function(){
      axios.get(tools.url('/api/comunicados')).then((response)=>{
        this.blogs = response.data;
      });
    }
  },

  beforeMount(){
    this.getComunicados();
  },
}
</script>
