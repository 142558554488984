import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

//Componentes
//import Login from './components/admin/Login.vue';

const page="./components/page/";

const MyRouter = new VueRouter({
  	routes:[
	    { path: '/', name: 'homePage', component: require(page+'home/index.vue').default },
      { path: '/planes', component: require(page+'planes/index.vue').default, meta:{title:"Planes"}},
      { path: '/instalaciones', component: require(page+'instalaciones/index.vue').default, meta:{title:"Instalaciones"}},
      { path: '/instalaciones/:id', component: require(page+'instalaciones/detail.vue').default, meta:{title:"Instalaciones"}},
      
      { path: '/preguntas-frecuentes', component: require(page+'preguntas-frecuentes/index.vue').default, meta:{title:"Preguntas frecuentes"}},
      { path: '/concepto', component: require(page+'concepto/index.vue').default, meta:{title:"Concepto"}},
      { path: '/atencion-a-socios', component: require(page+'atencion-a-socios/index.vue').default, meta:{title:"Atención a socios"}},
      { path: '/easylover', component: require(page+'easylover/index.vue').default, meta:{title:"Easylover"}},
      { path: '/contacto', component: require(page+'contacto/index.vue').default, meta:{title:"Contacto"}},
      { path: '/aviso-de-privacidad', component: require(page+'text-pages/aviso.vue').default, meta:{title:"Aviso de Privacidad"}},
      { path: '/terminos-y-condiciones', component: require(page+'text-pages/terminos.vue').default, meta:{title:"Términos y Condiciones"}},

      { path: '/sucursales/:id', component: require(page+'sucursales/index.vue').default, meta:{title:"Sucursales"}},

      { path: '/beneficios', name: 'benefitsPage', component: require(page+'beneficios/index.vue').default, meta:{title:"Beneficios"}},
      { path: '/beneficios/resultados', component: require(page+'beneficios/results.vue').default, meta:{title:"Beneficios"}},
      { path: '/beneficios/preguntas-frecuentes', component: require(page+'beneficios/preguntas-frecuentes.vue').default, meta:{title:"Preguntas frecuentes"}},
      { path: '/como-usar-tu-tarjeta', component: require(page+'como-usar-tu-tarjeta/index.vue').default, meta:{title:"Tarjeta de beneficios"}},

      { path: '/comunicados', component: require(page+'comunicados/index.vue').default, meta:{title:"Comunicados"}},
      { path: '/comunicados/:id', component: require(page+'comunicados/detail.vue').default, meta:{title:"Comunicados"}},

      { path: '/bolsa-de-trabajo', component: require(page+'bolsa-de-trabajo/index.vue').default, meta:{title:"Bolsa de trabajo"}},
      { path: '/bolsa-de-trabajo/:id', component: require(page+'bolsa-de-trabajo/detail.vue').default, meta:{title:"Bolsa de trabajo"}},

      { path: '/clases', component: require(page+'clases/index.vue').default, meta:{title:"Clases"}},
      { path: '/clases/:id', name: 'clasePage', component: require(page+'clases/detail.vue').default, meta:{title:"Clase"}},

      { path: '/promociones/:id', name: 'promoDescPage', component: require(page+'promociones/detail.vue').default, meta:{title:"Promociones"}},

      

	    // { path: '/checkout', component: require(page+'checkout.vue').default, meta:{title:"Checkout"}},
	  ]
});

MyRouter.beforeEach((to, from, next) => {
	window.scrollTo(0,0);
	if(window.app.__vue__ && window.app.__vue__.$refs.loadingBar){
		window.app.__vue__.$refs.loadingBar.start();
	}
	next();
});

MyRouter.afterEach((to, from) => {

	if(window.app.__vue__ && window.app.__vue__.$refs.loadingBar){
		setTimeout(()=>{
			window.app.__vue__.$refs.loadingBar.done();
		},500);
	}


});

//Titulos del website
import VueDocumentTitlePlugin from "vue-document-title-plugin";
Vue.use(VueDocumentTitlePlugin, MyRouter,
	{ defTitle: "Easy Fit – La manera de hacer ejercicio de manera cómoda y familiar", filter: (title)=>{ return title+" - Easy Fit – La manera de hacer ejercicio de manera cómoda y familiar"; } }
);

// export {routes};
export default MyRouter;
