<template lang="html">
  <div id="class-description-page">

    <section class="description-section">
      <div class="placed-backg class-image" v-bind:style="{ backgroundImage: 'url('+clase.image+')' }"></div>

      <div class="container oversized-container">
        <div class="row">
          <div class="col-md-6 col-lg-7 col-images">
            <h2 class="title">Clases</h2>
          </div>

          <div class="col-md-6 col-lg-5 col-info">
            <h1 class="mb-4 mb-md-5 title"><span>{{ clase.nombre }}</span></h1>

            <h5 class="subcategory">Intensidad</h5>
            <div class="descr">{{ clase.intensidad }}</div>

            <h5 class="mt-4 subcategory">Objetivo</h5>
            <div class="descr" v-html="clase.objetivo"></div>

            <h5 class="mt-4 subcategory">Descripción</h5>
            <div class="descr" v-html="clase.descripcion"></div>

            <h5 class="mt-4 subcategory">Calorías</h5>
            <div class="descr">{{ clase.calorias }}</div>

            <div class="box-buttons">
              <a class="t-250 btn-schedule" @click="$refs['modal-schedule'].show()">Consultar Horarios</a>
              <!-- <a class="t-250 btn-inscription" @click="$refs['modal-register'].show()">Inscribirme</a> -->
              <router-link class="t-250 btn-inscription" to="/planes">Inscribirme</router-link>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Modal inscribirse -->
    <b-modal ref="modal-register" modal-class="modal-s1" size="lg" centered hide-footer no-close-on-backdrop no-close-on-esc title="">
      <div class="box-top">
        <h5 class="modal-title">Incríbete</h5>
      </div>

      <b-form class="col-12 px-2" @submit="onSubmit">
        <div class="row">
          <b-form-group class="col-lg-6 custom-f-group-1"
            label="Nombre:">
            <b-form-input size="sm"
              v-model="registroForm.nombre"
              type="text"
              placeholder="Nombre(s)"
              required
            ></b-form-input>
          </b-form-group>

          <b-form-group class="col-lg-6 custom-f-group-1"
            label="Apellidos:">
            <b-form-input size="sm"
              v-model="registroForm.apellidos"
              type="text"
              placeholder="Apellidos"
              required
            ></b-form-input>
          </b-form-group>

          <b-form-group class="col-lg-6 custom-f-group-1"
            label="Fecha de nacimiento:">
            <b-form-input size="sm"
              v-model="registroForm.fecha"
              type="date"
              placeholder="DD - MM - AAAA"
              required
            ></b-form-input>
          </b-form-group>

          <b-form-group class="col-lg-6 custom-f-group-1"
            label="Teléfono:">
            <b-form-input size="sm"
              v-model="registroForm.tel"
              type="text"
              placeholder=""
              minlegth="10"
              maxlength="10"
              required
            ></b-form-input>
          </b-form-group>

          <b-form-group class="col-12 custom-f-group-1"
            label="Correo:">
            <b-form-input size="sm"
              v-model="registroForm.email"
              type="email"
              placeholder="correo@ejemplo.com"
              required
            ></b-form-input>
          </b-form-group>

          <div class="col-12 mt-2">
            <b-button type="submit" class="w-100 btn-s-1 btn-lg blue">Confirmar</b-button>
          </div>
        </div>
      </b-form>
    </b-modal>
    <!--  -->

    <!-- Modal inscribirse -->
    <b-modal ref="modal-schedule" modal-class="modal-s1 modal-schedule" centered hide-footer no-close-on-backdrop no-close-on-esc title="">
      <div class="box-top">
        <h5 class="modal-title">Horarios</h5>
      </div>

      <div class="content">
        <div class="row justify-content-center">
          <b-form-group class="col-lg-8 custom-f-group-1"
            label="Sucursal:">
            <b-form-select size="sm" v-model="image">
              <b-form-select-option v-for="(h, index) in horarios" :key="index" :value="h.image">{{ h.sucursal }}</b-form-select-option>
            </b-form-select>
          </b-form-group>

          <div class="col-12 text-center col-table" v-if="image">
            <img class="img-table" :src="image">
          </div>
        </div>
      </div>
    </b-modal>

  </div>
</template>

<script>
export default {
  data(){
    return{
      clase: [],

      registroForm: {
        nombre: null,
        apellidos: null,
        fecha: null,
        tel: null,
        email: null
      },

      horarios:[],
      image: null,

      horarioForm: {
        sucursal: 'a',
      },
    }
  },

  methods: {
    onSubmit(event) {
      event.preventDefault();
    },

    getClases(){
      axios.get(tools.url("/api/clases/"+this.$route.params.id)).then((response)=>{
        this.image = null;
        this.clase = response.data;
      }).catch((error)=>{
        console.error('Error al obtener la info de la Clase.');
      });
    },

    getHorarios(){
      this.horarios = [];
      axios.get(tools.url("/api/horarios/"+this.$route.params.id)).then((response)=>{
        this.horarios = response.data
      }).catch((error)=>{
        console.error('Error al obtener la info.');
      });
    }
  },

  beforeMount(){
    this.getClases();
    this.getHorarios();
  },

  watch: {
   '$route' (to, from){
      this.getClases();
      this.getHorarios();
    }
  }
}
</script>
