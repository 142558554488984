<template lang="html">
  <div id="faqs-page">

    <section class="container oversized-container main-section">
      <div class="row justify-content-center">
        <div class="col-12 col-title">
          <h5 class="title-s-1 txt-blue">Preguntas frecuentes</h5>
        </div>

        <div class="col-10 col-faqs">
          <div class="my-4" role="tablist" v-html='faqs'>
            <!-- <b-card no-body class="mb-3" v-for="(qa, qaInx) in faqs" :key="'qaInx-'+qaInx">
              <b-card-header header-tag="header" class="" role="tab">
                <a v-b-toggle="'accordion-'+qaInx" class="question">{{ qa.q }}</a>
              </b-card-header>

              <b-collapse :id="'accordion-'+qaInx" :visible="qa.show" accordion="my-accordion" role="tabpanel">
                <b-card-body v-html="qa.a"></b-card-body>
              </b-collapse>
            </b-card> -->
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
  data(){
    return{
      faqs: [],
    }
  },

  beforeMount(){
    axios.get(tools.url("/api/faq/")).then((response)=>{
      this.faqs = response.data
    }).catch((error)=>{
      console.error('Error al obtener la info de FAQs.');
    });
  }
}
</script>
