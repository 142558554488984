<template lang="html">
  <div>
    <!-- Modal Tramite y descarga en Linea -->
    <b-modal ref="modal-plan" modal-class="modal-s1 modal-plan" size="lg" @hidden="destroyModal" centered hide-footer no-close-on-backdrop no-close-on-esc title="">
      <div class="box-top">
        <h5 class="modal-title">Escoge tu plan</h5>
      </div>

      <b-form class="col-12 px-2" @submit="onSubmitPlan">
        <div class="row">
          <b-form-group class="col-md-6 custom-f-group-1"
            label="Nombre:">
            <b-form-input size="sm"
              v-model="planForm.nombre"
              type="text"
              placeholder="Nombre(s)"
              required
            ></b-form-input>
          </b-form-group>

          <b-form-group class="col-md-6 custom-f-group-1"
            label="Apellidos:">
            <b-form-input size="sm"
              v-model="planForm.apellidos"
              type="text"
              placeholder="Apellidos"
              required
            ></b-form-input>
          </b-form-group>

          <b-form-group class="col-md-6 custom-f-group-1"
            label="Fecha de nacimiento:">
            <b-form-input size="sm"
              v-model="planForm.fecha_nacimiento"
              type="date"
              placeholder="DD - MM - AAAA"
              required
            ></b-form-input>
          </b-form-group>

          <b-form-group class="col-md-6 custom-f-group-1"
            label="Teléfono (10 dígitos):">
            <b-form-input size="sm"
              v-model="planForm.telefono"
              type="text"
              placeholder="1234567890"
              minlength="10"
              maxlength="10"
              required
            ></b-form-input>
          </b-form-group>

          <b-form-group class="col-12 custom-f-group-1"
            label="Correo electrónico:">
            <b-form-input size="sm"
              v-model="planForm.correo"
              type="email"
              placeholder="correo@email.com"
              required
            ></b-form-input>
          </b-form-group>

          <!-- <div class="col-12 box-target-content">
            <b-form-group class="col-12 px-0 custom-f-group-1" v-slot="{ ariaDescribedbyObje }" label="¿Cuál es tu objetivo?">
              <b-form-radio-group class="row target-group"
                v-model="planForm.objetivo"
                :aria-describedby="ariaDescribedbyObje"
                name="radio-sub-objetivo" required>
                <b-form-radio value="Bajar de peso"><div class="box-target">Bajar de peso</div></b-form-radio>
                <b-form-radio value="Tonificar"><div class="box-target">Tonificar</div></b-form-radio>
                <b-form-radio value="Ganar músculo"><div class="box-target">Ganar músculo</div></b-form-radio>
                <b-form-radio value="Fuerza"><div class="box-target">Fuerza</div></b-form-radio>
              </b-form-radio-group>
            </b-form-group>
          </div> -->

          <!-- <div class="col-12 box-target-content">
            <b-form-group class="col-12 px-0 custom-f-group-1" v-slot="{ ariaDescribedbyPaqu }" label="¿Qué paquete necesitas?">
              <b-form-radio-group class="row target-group group-txt-sm"
                v-model="planForm.paquete"
                :aria-describedby="ariaDescribedbyPaqu"
                name="radio-sub-paquete" required>
                <b-form-radio value="Entrenamiento personalizado"><div class="box-target">Entrenamiento personalizado</div></b-form-radio>
                <b-form-radio value="Entrenamiento personalizado+plan de alimentación"><div class="box-target">Entrenamiento personalizado<br />+plan de alimentación</div></b-form-radio>
              </b-form-radio-group>
            </b-form-group>
          </div> -->

          <div class="col-12 mt-2">
            <b-button type="submit" class="w-100 btn-s-1 btn-lg blue">Incribirme al plan</b-button>
          </div>
        </div>
      </b-form>
    </b-modal>
    <!--  -->
  </div>
</template>

<script>
export default {
  data(){
    return{
      planForm: {
        nombre: null,
        apellidos: null,
        fecha_nacimiento: null,
        telelefono: null,
        correo: null,
        objetivo: null,
        plan_id: null,
        sucursal_id: null,
      },
    }
  },

  methods: {
    destroyModal(){
      setTimeout(()=>{ this.$root.show_ModalSeleccionaPlan = false; }, 350);
    },

    onSubmitPlan(event) {
      event.preventDefault();
      this.planForm.plan_id = this.$root.modalSeleccionaPlan_plan_id;
      axios.post(tools.url("/api/inscripciones"),this.planForm).then((response)=>{
              this.planForm.nombre = null;
              this.planForm.apellidos = null;
              this.planForm.fecha_nacimiento = null;
              this.planForm.telefono = null;
              this.planForm.correo = null;
              this.planForm.objetivo = null;
              this.planForm.paquete = null;
              this.planForm.plan_id = null;
              this.planForm.sucursal_id = null;

              alert('Registro completado!');

          }).catch((error)=>{
              alert('Ocurrio un error al guardar la información');
        });

      setTimeout(()=>{ this.$root.show_ModalSeleccionaPlan = false; }, 350);
    },
  },

  watch: {
   '$root.show_ModalSeleccionaPlan': function(value, oldValue){ // Mostrar u ocultar este modal/componente
      if(value){
        this.$refs['modal-plan'].show();
      }else{
        this.$refs['modal-plan'].hide();
      }
    }
  }
}
</script>
